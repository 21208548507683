export default {
    logging_in: 'Logging In',
    authentication_code_can_not_be_used: 'Authentication code has expired or has already been used for authentication. Please request a new code for login.',
    authentication_is_not_done: 'Authentication is not complete. Please use the provided link from the configuration menu requested from the used messenger.',
    logged_out: 'Logged Out',
    log_out: 'Log out',
    types: 'Types',
    project_types: 'Project Types',
    shift_types: 'Shift Types',
    absences_types: 'Absences Types',
    roles: 'Roles',
    employees: 'Employees',
    clients: 'Clients',
    projects: 'Projects',
    settings: 'Settings',
    preferences: 'Preferences',
    notifications: 'Notifications',
    integrations: 'Integrations',
    earlyAccess: 'Early Access',
    workCalendar: 'Work Сalendar',
    billing: 'Billing',
    payments: 'Payment methods',
    currentPaymentMethod: 'Current Payment Method',
    paymentMethod_setup: 'Stripe is set',
    you_have_no_payment_method_added: 'You have not added a payment method',
    you_have_no_payment_method_added_desc: 'Add your debit or credit card to activate your subscription',
    add_payment_method: 'Add Payment Method',
    subscription: 'Subscription',
    timinator_trial: 'Trial Subscription',
    timinator_connected: 'Timinator Connected',
    timinator_free: 'Free',
    timinator_paid: 'T-1000',
    timinator_full_potential: 'discover its full potential',
    your_sub_active_unt: 'Your subscription is active until {s}',
    per_human_per_month: 'For just {s} per human per month',
    try_timinator: 'Or try Timinator for free for 30 days',
    next_payment: 'Next Payment',
    get_list_of_features: 'Get the full list of features on',
    timinator_page: 'Timinator Page',
    profile: 'Profile',
    manage: 'Manage',
    admin: 'admin',
    email: 'Email',
    create_new: 'Create new',
    delete: 'Delete',
    me: 'Me',
    required: 'required',
    max_length_error: 'Must be 45 characters or less',
    phone_number_is_not_valid: 'Phone number is not valid',
    standard_work_day: 'standard work day',
    weekend_or_day_off: 'weekend or day off',
    customized: 'customized',
    empty_field_ability: 'You could use empty value for use global setting from workspace.',
    work_hours: 'Work hours',
    day_type: 'Day type',
    date_settings: 'Date settings: {s}',
    calendar: 'Calendar',
    year: 'Year',
    work_calendar: 'work calendar',
    calendarName: 'Calendar name',
    region: 'Region',
    workHours_in_day: 'Work hours in a day',
    workHours_in_shortDay: 'Work hours in a short day',
    edit_work_calendar_info: 'If change this value then all custom dates will be reseted for it calendar.',
    delete_work_calendar: 'Delete work calendar',
    delete_work_calendar_info: 'Are you sure that you want to delete "{s}" Work calendar',
    integration_connect: 'Connect',
    integration_disconnect: 'Disconnect',
    integration_configure: 'Configure',
    integration_disconnect_title: 'Disconnect {s}',
    integration_disconnect_info: 'Disconnect integration',
    integration_disconnect_desc: 'Are you sure that you want to disconnect "{s}" integration?',
    integration_disconnect_done: 'Disconnected: {s} integration',
    integration_name: 'Integration {s}',
    zoho_integration_link: 'https://www.zoho.com',
    zoho_integration_desc: 'Read more about service',
    zoho_organizations: 'Organizations',
    zoho_employees_for_notify: 'Employees for notifications',
    select_one_more_projects: 'Select one or more projects',
    select_one_more_employees: 'Select one or more employees',
    integration_wating_for_configuration: 'Waiting for configuration',
    unknown_error: 'Unknown error.',
    we_re_working_on_it: 'We are already aware and working on it.',
    zoho_created_successfully: 'Your zoho account was added successfully.',
    zoho_request_not_found: 'We have not found the number of your request for add a zoho integration.',
    try_again: 'Try again or contact us',
    press_da_button_kronk: 'You could press the button below to back to the settings.',
    early_access_desc: 'You can get early access to new features and functions. But they may contain bugs or work incorrectly.\nBug fixes are distributed to everyone equally, regardless of early access.',
    time_z0: '+00:00 UTC Time Format',
    time_z1: '+01:00 (Dublin, London)',
    time_z2: '+02:00 (Amsterdam, Berlin, Oslo, Belgrade, Stockholm)',
    time_z3: '+03:00 (Moscow, Minsk, Kiev, Bucharest)',
    time_z4: '+04:00 (Dubai, Samara, Izhevsk)',
    time_z5: '+05:00 (Astana, Yekaterinburg, Dushanbe, Tashkent)',
    time_z6: '+06:00 (Chittagong, Bishkek, Omsk)',
    time_z7: '+07:00 (Bangkok, Hanoi, Krasnoyarsk)',
    time_z8: '+08:00 (Beijing, Ulan Bator, Singapore, Irkutsk)',
    time_z9: '+09:00 (Tokyo, Seoul, Chita)',
    time_z10: '+10:00 (Canberra, Vladivostok)',
    time_z11: '+11:00 (Kingston, Yuzhno-Sakhalinsk)',
    time_z12: '+12:00 (Wellington, Suva, Majuro)',
    time_z13: '+13:00 (Kiribati, Apia, Tokelau)',
    time_z14: '+14:00 (Tabwakea Village)',
    time_zw1: '-01:00 (Nuuk)',
    time_zw2: '-02:00 (Saint-Pierre)',
    time_zw3: '-03:00 (Bahia, Minas Gerais, New Brunswick)',
    time_zw4: '-04:00 (Quebec, Puente Alto, Florida, Michigan)',
    time_zw5: '-05:00 (Jamaica, Tennessee, Texas)',
    time_zw6: '-06:00 (Nunavut, Costa Rica, New Mexico)',
    time_zw7: '-07:00 (California, Yukon)',
    time_zw8: '-08:00 (Adamstown, Anchorage)',
    time_zw9: '-09:00 (Rikitea)',
    time_zw10: '-10:00 (Hawaii)',
    time_zw11: '-11:00 (Alofi, Pago Pago)',
    time_zw12: '-12:00 (Howland Island)',
    time_zone: 'Time zone',
    start_day_time: 'Start day time',
    end_day_time: 'End day time',
    hide_menu: 'Hide menu',
    item_of_item: '{f} of {s}',
    collapse: 'Collapse',
    collapse_all: 'Collapse all',
    shift: 'Shift',
    type: 'Type',
    status: 'Status',
    schedule: 'Time tracking',
    timesheet: 'Time sheet',
    timereport: 'Time report',
    absence: 'Absence',
    schedule_timeshift_date: 'Date',
    schedule_timeshift_target: 'Project / Absence',
    schedule_timeshift_type: 'Type',
    schedule_timeshift_note: 'Comment',
    schedule_timeshift_internal_note: 'Internal note',
    schedule_timeshift_hours: 'Hours',
    schedule_timeshift_approve: 'Approve ({s})',
    schedule_timeshift_bill: 'Bill ({s})',
    schedule_timeshift_approve_all: 'Approve all ({s})',
    schedule_timeshift_approve_shift: 'Approve shift ({s})',
    schedule_timeshift_bill_all: 'Bill all ({s})',
    schedule_timeshift_bill_shift: 'Bill shift ({s})',
    schedule_timeshift_disapprove_all: 'Disapprove all ({s})',
    schedule_timeshift_disapprove_shift: 'Disapprove shift ({s})',
    schedule_timeshift_disapprove: 'Disapprove',
    schedule_timeshift_cancel_bill_all: 'Cancel billing ({s})',
    schedule_timeshift_cancel_bill: 'Cancel billing',
    schedule_total_hours: 'Total',
    schedule_regular_hours: 'Regular',
    schedule_approved_hours: 'Approved',
    schedule_billable_hours: 'Billable',
    schedule_overtime_hours: 'Overtime',
    schedule_dayoff_hours: 'Day-off',
    schedule_employee_view: 'Employee View',
    schedule_project_view: 'Project View',
    schedule_filter_by_employees: 'Filter by Employees',
    schedule_filter_by_projects: 'Filter by Projects',
    schedule_filter_by_types: 'Filter by Types',
    schedule_create_report: 'Create report',
    schedule_create_timeshift_shift_to_this_day: 'Add shift to this day',
    schedule_create_timeshift_shift: 'Add shift',
    schedule_edit_timeshift_shift: 'Edit shift',
    schedule_type_day: 'Day',
    schedule_type_week: 'Week',
    schedule_type_month: 'Month',
    schedule_nothing_to_approve: 'Nothing to approve',
    schedule_nothing_to_bill: 'Nothing to bill',
    shift_type: 'Shift type',
    employee: 'Employee',
    project: 'Project',
    expand_all: 'Expand all',
    show_smth: 'Show {s}',
    hide_smth: 'Hide {s}',
    report: 'Report',
    create: 'Create',
    edit: 'Edit',
    currentMonth: 'Current month',
    nextMonth: 'Next month',
    thisWeek: 'This week',
    lastWeek: 'Last week',
    custom: 'Custom',
    previousMonth: 'Previous month',
    employee_first_name: 'First name',
    employee_last_name: 'Last name',
    employee_position: 'Position',
    employee_admin: 'Admin',
    phonenumber: 'Phonenumber',
    form_name_required_format: '{s} name required',
    form_n_required_format: '{s} required',
    project_type: 'Project type',
    absence_type: 'Absence type',
    role: 'Role',
    client: 'Client',
    schedule_delete_timeshift_item: 'Delete item',
    schedule_delete_timeshift_shift: 'Delete shift',
    schedule_delete_timeshift_absence: 'Delete absence',
    schedule_edit_timeshift_absence: 'Edit absence',
    schedule_worked_hours_abbreviation: 'WH',
    schedule_absence_hours_abbreviation: 'AH',
    schedule_overtime_hours_abbreviation: 'OV',
    filter: 'Filter',
    reset_filter: 'Reset filter',
    apply_filter: 'Apply filter',
    clear: 'Clear'
}
