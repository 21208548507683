import React from 'react';
import styles from './row-header.module.scss';
import {Box} from '@mui/material';
import {IntlShape} from 'react-intl';
import TotalV2 from '../../total-v2';
import TimeSheetGroupTool from '../../timesheet-group-tool';

interface Props {
    name: string,
    total: number,
    approved: number,
    billed: number,
    approvable: number;
    billable: number;
    intl: IntlShape;
    onApproveAllClick: () => Promise<void>;
    onBillAllClick: () => Promise<void>;
    onDisapproveAllClick: () => Promise<void>;
    onCancelAllBillingClick: () => Promise<void>;
    onAddNewShiftClick: () => Promise<void>;
    hideButtons?: boolean;
    hideTrackingHours?: boolean;
    workHours?: number;
    absenceHours?: number;
}

const DataRowHeader: React.FC<Props> = (props) => {

    return(<Box className={styles.container}>
        <span className={styles.name}>{props.name}</span>
        <div className={styles.info}>
            <TotalV2 workHours={props.workHours}
                absenceHours={props.absenceHours}
                approved={props.approved} 
                billed={props.billed}
                approvable={props.approvable}
                billable={props.billable}
                totalHours={props.total}
                hideTrackingHours={props.hideTrackingHours}/>
            {
                props.hideButtons === undefined || !props.hideButtons && <>
                    <TimeSheetGroupTool intl={props.intl}
                        totalHours={props.total}
                        totalWorkHours={props.workHours}
                        approvable={props.approvable}
                        billable={props.billable}
                        approved={props.approved}
                        billed={props.billed}
                        onAddNewShiftClick={props.onAddNewShiftClick}
                        onCancelBillClick={props.onCancelAllBillingClick}
                        onBillClick={props.onBillAllClick}
                        onDisapproveClick={props.onDisapproveAllClick}
                        onApproveClick={props.onApproveAllClick} />
                </>
            }
        </div>
    </Box>)
}

export default DataRowHeader