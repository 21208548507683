import React from 'react';
import styles from './time-report-grid.module.scss';
import classNames from 'classnames';

interface Props {
    employeeName: string;
    workHours: number;
    approvedHours: number;
    billedHours: number;
    absenceHours: number;
    overtimeHours: number;
}

const GroupEmployeeRow: React.FC<Props> = (props) => {
    return (<div className={styles.containerTimeReportGroup}>
        <div className={styles.name}>{props.employeeName}</div>
        <div className={styles.info}>
            <div className={styles.hours}>
                <div className={styles.cell}>{props.absenceHours === 0 ? '' : props.absenceHours}</div>
                <div className={styles.cell}>{props.overtimeHours === 0 ? '' : props.overtimeHours}</div>
                <div className={styles.cell}>{props.workHours === 0 ? '' : props.workHours}</div>
                <div className={classNames({
                    [styles.cell]: true,
                    [styles.approved]: true,
                    [styles.isZero]: props.approvedHours === 0
                })}>{props.approvedHours}</div>
                <div className={classNames({
                    [styles.cell]: true,
                    [styles.billed]: true,
                    [styles.isZero]: props.billedHours === 0
                })}>
                    {props.billedHours}</div>
            </div>
        </div>
    </div>)
}

export default GroupEmployeeRow;