import React, {useEffect, useState} from 'react'
import PageWrapper from '../../components/shared/page-wrapper'
import TiminatorTable from '../../components/shared/table';
import IconTextLarge from '../../components/shared/large-icon-text';
import {SVGS} from '../../assets/images';
import {Box} from '@mui/material';
import {useAppDispatch, useAppSelector} from '../../hooks/hooks';
import {useTableData} from '../../hooks/useTableData';
import {Column} from '../../components/shared/table/types';
import Status from '../../components/shared/status';
import {store} from '../../store/store';
import {selectIdOnDelete, selectIdOnEdit} from '../../features/modals';
import CreateEmployeeModal from './components/create-modal';
import EditEmployeeModal from './components/edit-modal';
import DeleteEmployeeModal from './components/delete-modal';
import {getEmployees} from '../../features/employees';
import {getRoles} from '../../features/roles/filter';
import {getProjects} from '../../features/projects/filter';
import {Employee} from '../../features/employees/types';
import {FormattedMessage} from 'react-intl';
import {PopupMenuAction} from '../../components/shared/popup-menu/types';

const columns: Column[] = [
    {
        id: 'name',
        label: 'Name',
        format: (rowData: Employee) => {
            return <span>
                <>
                    <div>
                        {rowData.firstName} {rowData.lastName} {rowData.position && <span style={{color: '#F26D6B', fontSize: '0.75rem', fontWeight: '400'}}>{rowData.position}</span>}
                    </div>
                    {rowData.projects.length > 0 && <div style={{marginTop: '0.8rem', fontSize: '0.75rem', fontWeight: '400'}}>
                        {rowData.projects.map((project, index) => {
                            return (index == rowData.projects.length - 1 
                                ? <span key={project.id} style={{marginRight: '0.3rem'}}>{project.name}</span>
                                : <span key={project.id} style={{marginRight: '0.3rem'}}>{project.name}&#44;</span>)
                        })}
                    </div>}
                </>
            </span>
        }
    },
    {
        id: 'status',
        label: 'Status',
        isStatus: true,
        align: 'left',
        format: (rowData: Employee) => <Status color={rowData.isActive ? '#59CA59' : '#F26D6B'} value={rowData.isActive ? 'Active' : 'Suspended'} />
    },
];

const actions: PopupMenuAction[] = [
    {
        name: 'Edit',
        icon: <SVGS.EditIcon />,
        callback: function(rowData: Employee) {
            store.dispatch(selectIdOnEdit(rowData))
        },
    },
    {
        name: 'Delete',
        icon: <SVGS.DeleteIcon />,
        callback: function(rowData: Employee) {
            store.dispatch(selectIdOnDelete(rowData))
        },
    }
]

const mapData = (item: Employee): Employee => {
    return item;
}

const sort = (a: Employee, b: Employee): number => {
    if (a.isActive !== b.isActive) {
        return a.isActive ? -1 : 1;
    } else {
        return a.firstName > b.firstName ? 1 : -1;
    }
}

export default function EmployeesPage() {
    const dispatch = useAppDispatch();
    const employees = useAppSelector(store => store.employees);
    const eRoles = useAppSelector(store => store.eRoles)
    const eProjects = useAppSelector(store => store.eProjects)
    const selectedEdit = useAppSelector(store => store.modals.selectedEdit)
    const selectedDelete = useAppSelector(store => store.modals.selectedDelete)
    const tableSettings = useTableData(columns, actions, employees.items, employees.total, mapData, sort);
    const [isEmpty, setIsEmpty] = useState<boolean>(false);

    const fetchData = async (page: number) => {
        dispatch(getEmployees(page))
    }

    useEffect(() => {
        setIsEmpty(employees.total === 0);
    }, [employees.total])

    useEffect(() => {
        dispatch(getRoles());
        dispatch(getProjects())
    }, []);

    return  <PageWrapper>
        <h3><FormattedMessage id={'employees'} /></h3>
        <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%', overflowY: 'auto'}}>
            {
                !isEmpty
                    ? <TiminatorTable tableSettings={tableSettings} fetchData={fetchData} offset={employees.offset}/>
                    : <IconTextLarge text='You have no employees' subtext='Create employee and add detailed info about it' icon={<SVGS.EmployeesIcon />} />
            }
            <CreateEmployeeModal roles={eRoles} projects={eProjects}/>
            {selectedEdit && <EditEmployeeModal employee={selectedEdit as Employee} roles={eRoles} projects={eProjects}/>}
            {selectedDelete && <DeleteEmployeeModal employee={selectedDelete as Employee}/>}
        </Box>
    </PageWrapper>
};
