import React from 'react';
import styles from './collapse-button.module.scss';
import {IconButton, Tooltip} from '@mui/material';
import {SVGS} from '../../../assets/images';
import classNames from 'classnames';

interface Props {
    isExpanded: boolean;
    onExpandClick: () => void;
    tooltip?: string;
    displayText?: string;
}

export const CollapseButton = (props: Props) => {
    return (<Tooltip title={props.tooltip}>
        <div>
            <IconButton onClick={() => props.onExpandClick()} className={classNames({
                [styles.collapseButton]: true,
                [styles.active]: props.isExpanded
            })}>
                <SVGS.RightChevronIcon className={styles.icon} />
            </IconButton>
            {props.displayText && <span style={{paddingLeft:'8px'}}>{props.displayText }</span>}
        </div>       
    </Tooltip>);
};