import React, {ReactElement, useEffect, ReactNode, HTMLAttributes} from 'react';
import styles from './row-collapse.module.scss'
import classNames from 'classnames';
import { CollapseButton } from '../../../collapse-button';

export interface State {
    rowKey: any;
    open: boolean;
    isAbleToOpen: boolean;
    forceCollapse: () => void;
    forceExpand: () => void;
}

interface Props {
    rowKey: any;
    rowHeader: ReactElement;
    children: ReactNode[];
    onStateChange: (state: State) => void;
    nested: number;
    contentProps?: React.DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
    showButton?: boolean | null;
}

export const RowCollapse: React.FC<Props> = (props) => {
    const { rowKey: key, rowHeader, children, contentProps, showButton } = props;
    const [open, setOpen] = React.useState(false);
    const [init, setInit] = React.useState(false);

    const handleButtonClick = () => {
        setOpen((s) => {
            props.onStateChange({
                rowKey: key,
                open: !open,
                forceCollapse: () => setOpen(false),
                forceExpand: () => setOpen(true),
                isAbleToOpen: !!showButton,
            });

            return !open
        });
    }

    useEffect(() => {
        if (init) {
            return
        }

        props.onStateChange({
            rowKey: key,
            open: open,
            forceCollapse: () => setOpen(false),
            forceExpand: () => setOpen(true),
            isAbleToOpen: !!showButton,
        })

        setInit(true);
    }, []);

    return(<div key={key} aria-rowindex={key}>
        <div className={classNames({
            [styles.collapseHeader]: true,
            [styles.nested]: props.nested > 0
        })}>
            {showButton
                ?
                <CollapseButton 
                    isExpanded={open}
                    onExpandClick={() => handleButtonClick()} />
                : 
                <div className={styles.fakeCollapseButtonArea}></div>}
            {rowHeader}
        </div> 
        {
            open && showButton && <div className={classNames({
                [styles.collapseContent]: true,
                [styles.open]: open,
            })} {...contentProps} >
                {
                    children.map((c) => (c))
                }
            </div>
        }
    </div>)
}