import {Box, Skeleton} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import styles from './time-report-top-bar.module.scss'
import ScheduleDateSelector from '../../../../components/Schedule/header/components/schedule-date-selector';
import moment from 'moment';
import { Hours } from '../../../../features/schedule/types';
import TotalV2 from '../../../../components/Schedule/total-v2';
import { Year } from '../../../../features/settings/workcalendar/types';
import ReportModal from '../../../../components/Schedule/modals/report-modal';
import Filter from '../../../../components/Schedule/header/components/filter';
import { FilterState } from '../../../../components/Schedule/header/components/filter/types';
import TimeSheetGroupTool from '../../../../components/Schedule/timesheet-group-tool';
import { useAppSelector } from '../../../../hooks/hooks';
import { useTimeReportSearch } from '../../contexts/search';
import { updateTimeReportDateRangeFilter, updateTimeReportPropertyFilter } from '../../../../features/time-report';
import { 
    GetGroupedAbsenceHours, 
    GetGroupedApprovedHours, 
    GetGroupedBilledHours, 
    GetGroupedWorkHours, 
    TimeReportItem 
} from '../../../../features/time-report/types';
import classNames from 'classnames';

interface Props {
    year: Year;
    today: moment.Moment;
    loading: boolean;
    items: Array<TimeReportItem>;
}

const initialHoursState: Hours = {billed: 0, totalHours: 0, approved: 0}
export const TimeReportTopBar = ({ year, today, items, loading }: Props) => {
    const intl = useIntl();
    const { change: searchChange, value: search } = useTimeReportSearch();
    const dateRangeFilter = useAppSelector((selector) => selector.timereport.filter.dateRangeFilter);
    const [hours, setHours] = useState<Hours>(initialHoursState);
    const [dateRange, setDateRange] = useState<moment.Moment[]>([]);

    const ready = loading === undefined || !loading;

    const handleDateRangeChange = (values: moment.Moment[]) => new Promise<void>(() => {
        updateTimeReportDateRangeFilter({
            startDate: values[0].format('YYYY-MM-DD'),
            endDate: values[1].format('YYYY-MM-DD'),
        })
    });

    useEffect(() => {
        if (!items) {
            return setHours(initialHoursState);
        }

        const totalAbsenceHours = GetGroupedAbsenceHours(items);
        const totalWorkHours = GetGroupedWorkHours(items);
        setHours({
            totalHours: totalWorkHours + totalAbsenceHours,
            approved: GetGroupedApprovedHours(items),
            billed: GetGroupedBilledHours(items),
            workHours: totalWorkHours,
            absenceHours: totalAbsenceHours,
        })
    }, [items]);

    const handlePropertyFilterChanged = (value: FilterState) => new Promise<void>(() => {
        updateTimeReportPropertyFilter({
            selectedProjects: value.selectedProjects ?? [],
            selectedEmployees: value.selectedEmployees ?? [],
            selectedShiftTypes: value.selectedShiftTypes ?? [],
            selectedAbsenceTypes: [],
            selectedStatuses: [],
        })
    });

    useEffect(() => {
        setDateRange([
            moment(dateRangeFilter.startDate, 'YYYY-MM-DD'),
            moment(dateRangeFilter.endDate, 'YYYY-MM-DD'),
        ]);
    }, [dateRangeFilter])

    //initial
    useEffect(() => {
        handleDateRangeChange([
            today.clone().add(-1, 'week'),
            today
        ]).then();
    }, []);
    
    return (
        <div className={styles.container}>
            <Box className={styles.header}>
                <div className={classNames({
                    [styles.headerItem]: true,
                    [styles.scheduleSelector]: true
                })}>
                    <h3><FormattedMessage id={'timereport'} /></h3>
                </div>
                <Box className={styles.scheduleSelector}>
                    {
                        ready && year.year > 0 && dateRange.length > 0 ?
                            <ScheduleDateSelector selectedDates={dateRange} onRangeSelected={handleDateRangeChange} year={{
                                id: year.year, months: year.months.map((m) => ({
                                    id: m.month, days: m.days.map((d) => ({
                                        id: d.day,
                                        type: d.isShort ? 'short' : d.isHoliday ? 'off' : 'default'
                                    }))
                                }))
                            }}/> 
                            : <Skeleton width={500} />
                    }
                </Box>
                <Box className={styles.headerItem}>
                    { ready ? <ReportModal today={today} year={year} /> : <Skeleton width={50} /> }
                </Box>
            </Box>
            <Box className={styles.subheader}>
                <div className={styles.searchFilter}>
                    {
                        ready ? 
                            <input 
                                id={'search-bar-input'} 
                                className={styles.input} 
                                value={search}
                                onChange={(event) => searchChange(event.target.value)}
                                placeholder={'Search'} 
                                size={30}/>
                            : <Skeleton width={212}></Skeleton>
                    }
                    {   
                        ready ? 
                            <Filter 
                                onChange={handlePropertyFilterChanged} 
                                disableStatusFilter={true} 
                                disableAbsenceAndShiftTypesFilter={true} />
                            : <Skeleton width={115}></Skeleton>
                    }
                </div>
                <div className={styles.info}>
                    {
                        hours && <>
                            <TotalV2 totalHours={hours.totalHours}
                                approved={hours.approved}
                                billed={hours.billed}
                                absenceHours={hours.absenceHours}
                                workHours={hours.workHours}
                                hideTrackingHours={hours.workHours === undefined || hours.workHours < 1}/>
                            <TimeSheetGroupTool intl={intl} totalHours={hours.totalHours} approved={0} billed={0} approvable={0} billable={0} hideButtons />
                        </>
                    }
                </div>
            </Box>
        </div>
    )}