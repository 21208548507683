import {GridIdNameModel} from '../../types';
import React, {useEffect, useRef, useState} from 'react';
import {GridDropdown, GridDropdownOption} from '../dropdown';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

import styles from './edit-cell.module.scss';

interface Props {
    value: number,
    options: GridIdNameModel[],
    getOptions?: () => Promise<GridIdNameModel[]>,
    active: boolean,
    onChange: (value: any) => void,
    lazyLoad?: boolean,
    placeholder?: any
}

const DropdownEditCell = (props: Props) => {
    const {
        options,
        getOptions,
        active,
        onChange,
        lazyLoad,
        placeholder} = props;
    const ref = useRef<HTMLButtonElement>(null);
    const [value, setValue] = React.useState<number | null>(props.value);
    const [internalOptions, setInternalOptions] = useState<GridIdNameModel[] | undefined>(() => {
        if (getOptions && lazyLoad && lazyLoad) {
            return undefined
        }

        return options
    })

    const handleChange = (value: number | null) => {
        setValue(value);
        onChange(value);
    }

    useEffect(() => {
        if (!active) {
            ref.current?.blur();
        } else {
            if (getOptions) {
                getOptions().then((r) => {
                    setInternalOptions(r)
                })
            }
        }
    }, [active]);

    return(<GridDropdown ref={ref}
        multiple={false}
        value={value}
        autoFocus={true}
        aria-expanded={active}
        aria-haspopup={true}
        listboxOpen={active}
        onChange={(_, value) => handleChange(value)}>
        {
            internalOptions !== undefined ? internalOptions.length > 0 ? internalOptions.map((o, index) => (
                <GridDropdownOption key={o.id} value={o.id}>{o.name}</GridDropdownOption>)) : <Box display={'flex'} width={'100%'} justifyContent={'flex-start'} flexDirection={'column'} padding={'8px 14px'}>
                    No Data
            </Box>
                : <>
                    {placeholder && <GridDropdownOption key={value} value={value}>{placeholder}</GridDropdownOption>}
                    <Box display={'flex'} width={'100%'} justifyContent={'flex-start'} flexDirection={'column'}>
                        {
                            [1, 2].map((_x, index) => (<div key={index} className={styles.skeletonWrapper}><Skeleton animation={'wave'} /></div>))
                        }
                    </Box>
                </>
        }
    </GridDropdown>)
}

export default DropdownEditCell;