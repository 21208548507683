import Calendar from '../base-calendar';
import React, {useEffect, useRef, useState} from 'react';
import {CalendarDate, Props, RangeType} from '../../types';
import classNames from 'classnames';
import styles from './popup-calendar.module.scss';
import Popper from '@mui/material/Popper';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import {SVGS} from '../../../../../assets/images';
import {Button} from '@mui/material';
import {FormattedMessage} from 'react-intl';
import {formatDateRange, generateCurrentYear, getMonth, getWeek, isDefault, isHasRenderer, isRange} from '../../utils';
import moment from 'moment';

// Tried to not copy and paste cod from popup-menu.
const PopupCalendar: React.FC<Props> = (props) => {
    // var
    const {view, state, disablePortal, placement} = props;
    const year = props.year === undefined ? generateCurrentYear() : props.year;
    const range = isRange(props) && props.range;
    const onRangeComplete = range ? props.onRangeComplete : undefined;
    const value = isDefault(props) ? props.value : undefined;
    const selectedRange: CalendarDate[] | undefined = range ? props.value
        .map((x) => ({ year: x.year(), month: x.month() + 1, day: x.date() })) : value ? [{
        year: value.year(),
        month: value.month() + 1,
        day: value.date()
    }, {
        year: value.year(),
        month: value.month() + 1,
        day: value.date()
    }] : undefined;
    const renderer = isHasRenderer(props) ? props.renderer : undefined;

    // state
    const [open, setOpen] = useState(state?.open ?? false);
    const [firstCalendarDate, setFirstCalendarDate] = useState<CalendarDate | null>(null);
    const [previewCalendarDate, setPreviewCalendarDate] = useState<CalendarDate | null>(range && range ? {
        year: year.id,
        month: 0,
        day: 0
    } : null);
    const [rangeTypeState, setRangeTypeState] = useState<{
        current: RangeType,
        previous?: RangeType
    }>({current: RangeType.Custom});
    const anchorRef = useRef<HTMLDivElement>(null);
    const prevOpen = React.useRef(open);

    const resetDates = () => {
        if (firstCalendarDate !== null) {
            setRangeTypeState((state) => {
                return {
                    current: state.previous ?? RangeType.Custom,
                    previous: undefined
                }
            })
        }
        setFirstCalendarDate(null);
    }

    const handleToggle = () => {
        setOpen((prevOpen) => {
            state?.setOpen(!prevOpen)
            if (!prevOpen) {
                resetDates();
            }
            return !prevOpen
        });
    };

    const handleClose = (event: Event | React.SyntheticEvent) => {
        event.preventDefault();
        event.stopPropagation();
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
        ) {
            return;
        }
        resetDates();
        setOpen(false);
        state?.setOpen(false);
    };

    const rangeComplete = (dateRange: CalendarDate[]) => {
        if (range) {
            onRangeComplete?.(dateRange.map((x) => moment({ year: x.year, month: x.month - 1, date: x.day })));
        }
    }

    const handleOnDayClick = (year: number, month: number, day: number) => {
        if (range) {
            if (rangeTypeState.current !== RangeType.Custom) {
                setRangeTypeState((state) => {
                    return {
                        current: RangeType.Custom,
                        previous: state.current
                    }
                })
            }
            if (firstCalendarDate == null) {
                setFirstCalendarDate({year, month, day});
            } else {
                const dateRange = formatDateRange(firstCalendarDate, {year, month, day});
                setFirstCalendarDate(null);
                setOpen(false);
                rangeComplete(dateRange);
            }
        } else {
            props.onCalendarDateClick?.(moment({year: year, month: month - 1, day: day}));
        }
    };

    const handleOnDayOverEnter = (year: number, month: number, day: number) => {
        if (range && range) {
            if (firstCalendarDate === null) {
                return
            }
            setPreviewCalendarDate((prevDate) => {
                if (prevDate?.year !== year || prevDate?.month !== month || prevDate?.day !== day) {
                    return {year: year, month: month, day: day}
                }
                return prevDate
            })
        }
    };

    const handleOnDayOverLeave = (year: number, month: number, day: number) => {
        return void (0)
    };

    const handleActionClick = (action: RangeType) => {
        switch (action) {
            case RangeType.CurrentMonth:
            case RangeType.PreviousMonth:{
                const month = action === RangeType.CurrentMonth ? getMonth() : getMonth(-1);
                if (action !== rangeTypeState.previous) {
                    const dateRange = formatDateRange({
                        year: month.year, 
                        month: month.month, 
                        day: month.start
                    }, {
                        year: month.year, 
                        month: month.month, 
                        day: month.end
                    });
                    rangeComplete(dateRange);
                }
                break;
            }
            case RangeType.ThisWeek:
            case RangeType.LastWeek:{
                const month = action === RangeType.ThisWeek ? getWeek() : getWeek(-1);
                if (action !== rangeTypeState.previous) {
                    const dateRange = formatDateRange({
                        year: month.year,
                        month: month.start.month,
                        day: month.start.day
                    }, {
                        year: month.year, 
                        month: month.end.month, 
                        day: month.end.day
                    });
                    rangeComplete(dateRange);
                }
                break;
            }
            case RangeType.Custom:
                break;
        }
        if (action !== RangeType.Custom) {
            setOpen(false);
            if (firstCalendarDate !== null) {
                setFirstCalendarDate(null)
            }
            setRangeTypeState((state) => {
                const previousState = {...state}
                return {
                    current: action,
                }
            });
        }
        setRangeTypeState((state) => {
            const previousState = {...state}
            return {
                current: action,
                previous: previousState.current
            }
        });
    }

    useEffect(() => {
        if (prevOpen.current && !open) {
            anchorRef.current?.focus();
        }

        prevOpen.current = open;
    }, [open]);

    useEffect(() => {
        if (state?.open) {
            setOpen(state.open)
        }
    }, [state?.open]);

    return (
        <>
            <div ref={anchorRef}
                id="composition-button"
                aria-controls={open ? 'composition-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                className={classNames({
                    [styles.actionIcon]: !renderer,
                    [styles.small]: props.small,
                    [styles.active]: open,
                })}
            >
                {
                    renderer ? renderer
                        : <>
                            <SVGS.CalendarIcon/>
                        </>
                }
            </div>
            <Popper
                disablePortal={disablePortal}
                open={open}
                anchorEl={anchorRef.current}
                role={'banner'}
                placement={placement ?? 'bottom'}
                className={styles.popper}
                
                transition
            >
                {({TransitionProps, placement}) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                'center top',
                        }}
                    >
                        <Paper className={styles.paper} >
                            <ClickAwayListener onClickAway={handleClose}>
                                <div className={styles.calendarContainer}>
                                    {
                                        range && <>
                                            <div className={styles.actions}>
                                                <h6>Presets</h6>
                                                {
                                                    Object.values(RangeType).map((x, index) => {

                                                        return (<Button key={index} variant={'scheduleAction'}
                                                            sx={{justifyContent: 'flex-start'}}
                                                            className={classNames({
                                                                [styles.calendarPresetAction]: true,
                                                                [styles.active]: x == rangeTypeState.current
                                                            })}
                                                            onClick={() => handleActionClick(x)}><FormattedMessage
                                                                id={x}/></Button>)
                                                    })
                                                }
                                            </div>
                                            <span className={styles.separatorLine}/>
                                        </>
                                    }
                                    <Calendar year={year} view={view} size={'small'}
                                        onDateClick={handleOnDayClick}
                                        onDayOverEnter={handleOnDayOverEnter}
                                        onDayOverLeave={handleOnDayOverLeave}
                                        selectedRange={selectedRange}
                                        double={range}
                                        previewRange={firstCalendarDate ? [firstCalendarDate, previewCalendarDate ?? {
                                            year: 0,
                                            month: 0,
                                            day: 0
                                        }] : undefined}
                                        minDate={props.minDate}
                                        maxDate={props.maxDate}/>
                                </div>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
};

export default PopupCalendar;