import {Shift} from '../../features/shifts/types';
import {
    CreateShift,
    TimeSheetItem,
    TimeSheetItemStatus,
    TimeSheetItemUpdateState
} from '../../features/schedule/types';
import {TiminatorAbsenceType, TiminatorType} from '../../features/types/types';
import {Absence} from '../../features/absences/types';
import {Project} from '../../features/projects/types';

export const convertTimeshiftUpdateStateToShift = (updateState: TimeSheetItemUpdateState, shifts: Shift[]): Shift => {
    const shift = shifts.find((s) => s.id === updateState.timesheetId);
    if (!shift) {
        return <Shift>{
            approved: false,
            billable: false,
            comment: '',
            note: '',
            date: '',
            employeeId: 0,
            enteredHoursString: '',
            hours: 0,
            id: 0,
            isOvertime: false,
            project: {},
            recurringPeriod: 0,
            remote: false,
            shiftType: {}
        }
    }

    return <Shift>{
        id: shift.id,
        employeeId: shift.employeeId,
        shiftType: shift.shiftType,
        hours: shift.hours,
        comment: shift.comment,
        note: shift.note,
        date: shift.date,
        enteredHoursString: shift.enteredHoursString,
        isOvertime: shift.isOvertime,
        project: shift.project,
        recurringPeriod: shift.recurringPeriod,
        remote: shift.remote,
        approved: updateState.newStatus === TimeSheetItemStatus.APPROVED || updateState.newStatus === TimeSheetItemStatus.BILLABLE,
        billable: updateState.newStatus === TimeSheetItemStatus.BILLABLE
    }
}

export const convertTimeshiftUpdateStateToAbsence = (updateState: TimeSheetItemUpdateState, absences: Absence[]): Absence => {
    const absence = absences.find((s) => s.id === updateState.timesheetId);
    if (!absence) {
        return <Absence>{
            approved: false,
            comment: '',
            note: '',
            date: '',
            employeeId: 0,
            enteredHoursString: '',
            hours: 0,
            id: 0,
            absenceType: {
                id: 0,
                name: '',
                isVacation: false,
                active: false,
                default: false
            },
        }
    }

    return <Absence>{
        id: absence.id,
        employeeId: absence.employeeId,
        hours: absence.hours,
        comment: absence.comment,
        note: absence.note,
        date: absence.date,
        enteredHoursString: absence.enteredHoursString,
        approved: updateState.newStatus === TimeSheetItemStatus.APPROVED,
        absenceType: absence.absenceType
    }
}

export const toShift = (timeshift: TimeSheetItem, shifts: Shift[], shiftTypes: TiminatorType[], project?: Project): Shift => {
    const shift = shifts.find((x) => x.id === timeshift.itemId)
    if (!shift) {
        return <Shift>{ }
    }

    const updated = { ...shift };

    updated.date = timeshift.date;
    updated.shiftType = shiftTypes.find((x) => x.id === timeshift.timeshiftTypeId) ?? shiftTypes[0];
    updated.hours = timeshift.hours;
    updated.comment = timeshift.note;
    updated.note = timeshift.internalNote;
    updated.enteredHoursString = `${timeshift.hours}`;
    updated.approved = timeshift.status === TimeSheetItemStatus.APPROVED || timeshift.status === TimeSheetItemStatus.BILLABLE;
    updated.billable = timeshift.status === TimeSheetItemStatus.BILLABLE;
    if (project){
        updated.project = {
            id: project.id,
            name: project.name,
            isActive: project.isActive,
            projectType: {
                id: project.projectType.id,
                name: project.projectType.name,
                default: project.projectType.isDefault,
                active: true
            }
        }
    }

    return updated
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const toAbsence = (timeshift: TimeSheetItem, absences: Absence[], absenceTypes: TiminatorAbsenceType[]): Absence => {
    const absence = absences.find((x) => x.id === timeshift.itemId)
    if (!absence) {
        return <Absence>{ }
    }
    const updated = {...absence};

    updated.date = timeshift.date;
    updated.hours = timeshift.hours;
    updated.enteredHoursString = `${timeshift.hours}`;
    updated.comment = timeshift.note;
    updated.note = timeshift.internalNote;
    updated.absenceType = absenceTypes.find((x) => x.id === timeshift.targetId) ?? absenceTypes[0];

    return updated
}

export const isStatusAreEqual = (item: TimeSheetItem, storedItem: Shift | Absence): boolean => {
    if (item.status === TimeSheetItemStatus.BILLABLE) {
        return (storedItem.approved || storedItem.approved === undefined) && isEditShift(storedItem) ? storedItem.billable : true;
    }

    if (item.status === TimeSheetItemStatus.APPROVED) {
        return (storedItem.approved || storedItem.approved === undefined) && isEditShift(storedItem) ? !storedItem.billable : true;
    }

    return (storedItem.approved === undefined ? true : !storedItem.approved) && isEditShift(storedItem) ? !storedItem.billable : true;
}

export const isShiftDirty = (item: TimeSheetItem, shift: Shift): boolean => {
    return shift.date !== item.date
        || shift.project.id !== item.targetId
        || shift.shiftType.id !== item.timeshiftTypeId
        || shift.hours !== item.hours
        || shift.comment !== item.note
        || shift.note !== item.internalNote
        || !isStatusAreEqual(item, shift);
}

export const isAbsenceDirty = (item: TimeSheetItem, absence: Absence): boolean => {
    return absence.date !== item.date
        || absence.hours !== item.hours
        || absence.comment !== item.note
        || absence.note !== item.internalNote
        || absence.absenceType.id !== item.targetId
        || !isStatusAreEqual(item, absence);
};

export const isCreateShift = (obj: any): obj is CreateShift => {
    return obj != undefined && 'employees' in obj;
}

export const isEditShift = (obj: any): obj is Shift => {
    return obj != undefined && 'employeeId' in obj && 'shiftType' in obj;
}

export const isEditAbsence = (obj: any): obj is Absence => {
    return obj != undefined && 'employeeId' in obj && 'absenceType' in obj;
}
