import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {DateRangeFilter, PropertyFilter} from '../schedule/types';
import { TimeReportItem } from './types';

interface State {
    filter: {
        dateRangeFilter: DateRangeFilter;
        propertyFilter: PropertyFilter;
    },
    data: Array<TimeReportItem>
}

const initialState: State = {
    filter: {
        dateRangeFilter: {
            startDate: '',
            endDate: ''
        },
        propertyFilter: {
            selectedEmployees: [],
            selectedProjects: []
        }
    },
    data: []
}

const timeReportSlice = createSlice({
    name: 'timesheet',
    initialState,
    reducers: {
        clearData: (state) => {
            state.data = []
        },
        setData: (state, action: PayloadAction<Array<TimeReportItem>>) => {
            state.data = action.payload
        },
        updateDateRangeFilter: (state, action: PayloadAction<DateRangeFilter>) => {
            state.filter.dateRangeFilter = action.payload;
        },
        updatePropertyFilter: (state, action: PayloadAction<PropertyFilter>) => {
            state.filter.propertyFilter = action.payload;
        }
    }
})

export const { updateDateRangeFilter, updatePropertyFilter, clearData, setData } = timeReportSlice.actions;

export default timeReportSlice.reducer;