import moment from 'moment';
import {ReactElement, ReactNode} from 'react';
import {PopperPlacementType} from '@mui/material/Popper/BasePopper.types';

export type CalendarView = 'month' | 'weeks' | 'year';

export type DayType = 'default' | 'short' | 'off';

export type Size = 'small' | 'full';

export type Day = {
    id: number;
    type: DayType;
}

export type Month = {
    id: number;
    days: Array<Day>
}

export type Year = {
    id: number;
    months: Array<Month>
}

export type CalendarDate = {
    year: number;
    month: number;
    day: number;
}

export type InRangeResult = {
    commit: boolean;
    start: boolean;
    end: boolean;
}

export enum RangeType {
    CurrentMonth = 'currentMonth',
    PreviousMonth = 'previousMonth',
    ThisWeek = 'thisWeek',
    LastWeek = 'lastWeek',
    Custom = 'custom'
}

type BaseProps = {
    year?: Year;
    view: CalendarView;
    onCalendarDateClick?: (date: moment.Moment) => void;
    state?: { open: boolean, setOpen: (value: boolean) => void };
    disablePortal?: boolean;
    minDate?: moment.Moment;
    maxDate?: moment.Moment;
    small?: boolean;
    placement?: PopperPlacementType
}

export type SingleSelectionProps = BaseProps & {
    value: moment.Moment;
}

export type RangeSelectionProps = BaseProps & {
    range: boolean;
    value: moment.Moment[];
    onRangeComplete: (range: moment.Moment[]) => void
}

export type CustomRendererProps = (SingleSelectionProps | RangeSelectionProps) & {
    renderer: ReactElement;
}

export type Props = SingleSelectionProps | RangeSelectionProps | CustomRendererProps;

export interface CalendarProps {
    year: Year;
    view: CalendarView;
    size: Size;
    selectedRange?: CalendarDate[];
    previewRange?: CalendarDate[];
    onDateClick?: (year: number, month: number, day: number) => void;
    onDayOverEnter?: (year: number, month: number, day: number) => void;
    onDayOverLeave?: (year: number, month: number, day: number) => void;
    flexLayout?: boolean;
    double?: boolean;
    minDate?: moment.Moment;
    maxDate?: moment.Moment;
}

export interface MonthViewProps {
    onDayClick: (day: number) => void;
    onDayOverEnter: (day: number) => void;
    onDayOverLeave: (day: number) => void;
    month: Month;
    dateObject: moment.Moment;
    range?: { month: number, day: number };
    getDayIsInRange: (day: number) => InRangeResult | undefined;
    small?: boolean;
    minDate?: moment.Moment;
    maxDate?: moment.Moment;
}

export interface MonthViewBodyProps {
    startBlanks: Array<MonthDayCell>;
    endBlanks: Array<MonthDayCell>;
    days: Array<MonthDayCell>;
    isRangeEnabled: boolean;
    isInRange: (day: number) => InRangeResult | undefined;
    onDayOver?: (day: number) => void;
    onDayLeave?: (day: number) => void;
    small: boolean;
}

export interface MonthViewRowProps {
    children: MonthDayCell[] | ReactElement[];
    header?: boolean;
    isRangeEnabled: boolean;
    isInRange: (day: number) => InRangeResult | undefined;
    onDayOver?: (day: number) => void;
    onDayLeave?: (day: number) => void;
    small: boolean;
}

export interface MonthViewRowCellProps {
    children: ReactNode;
    isDay: boolean;
    header?: boolean;
    isRangeEnabled: boolean;
    onOver?: () => void;
    onLeave?: () => void;
    isInRange?: () => InRangeResult | undefined
    small: boolean;
}

export interface MonthViewReducerState {
    startBlankDays: Array<MonthDayCell>;
    endBlankDays: Array<MonthDayCell>;
    days: Array<MonthDayCell>;
}

export type MonthDayCell = {
    day: number;
    content: ReactElement;
}

export enum MonthViewReducerUpdateActionType {
    RELOAD_DAYS = 'RELOAD_DAYS',
    FULL_RELOAD = 'FULL_RELOAD'
}

export type MonthViewReducerUpdateAction = {
    type: MonthViewReducerUpdateActionType;
}