import { TimeSheetItemStatus } from '../features/schedule/types';
import { Shift } from '../features/shifts/types';

export function getShiftStatus(shift: Shift) {
    if (shift.approved && shift.billable) {
        return TimeSheetItemStatus.BILLABLE;
    } else if (shift.approved && !shift.billable) {
        return TimeSheetItemStatus.APPROVED;
    }

    return TimeSheetItemStatus.NORMAL;
}

export function getAbsenceStatus(approved?: boolean){
    return approved ? TimeSheetItemStatus.APPROVED : TimeSheetItemStatus.NORMAL;
}