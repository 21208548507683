import React, {useState} from 'react';
import {Form, Formik} from 'formik';
import {Lx} from '../../../../i18n/consts';
import {FormDropdown} from '../../../shared/nextgen/form/form-dropdown';
import {Box, Button, Tooltip} from '@mui/material';
import * as Yup from 'yup';
import CreateModal from '../../../shared/modal/create-modal';
import useModal from '../../../../hooks/useModal';
import {useIntl} from 'react-intl';
import {useAppDispatch, useAppSelector} from '../../../../hooks/hooks';
import styles from '../../header/schedule-header.module.scss';
import {SVGS} from '../../../../assets/images';
import {REmployees} from '../../../../features/roles/types';
import {PIdNameModel} from '../../../../features/projects/types';
import {Year} from '../../../../features/settings/workcalendar/types';
import FormDateSelector from '../../../shared/nextgen/form/form-date-selector';
import moment from 'moment';
import FormAutocomplete from '../../../shared/form-controls/form-autocomplete-tag';
import {AutoCompleteItemValue} from '../../../shared/form-controls/form-autocomplete-tag/types';
import {downloadReportByEmployee, downloadReportByProject, downloadReportDetailed} from '../../../../features/report';
import {FormCheckbox} from '../../../shared/form-controls/form-checkbox';

interface RequestReportForm {
    dateRange: string[]
    groupBy: number;
    employees?: REmployees[];
    projects?: PIdNameModel[];
    visibleDays?: number;
    hideEmptyProjects?: boolean;
}

interface Props {
    today: moment.Moment;
    year: Year;
}

enum GroupBy {
    ByProject = 1,
    ByEmployee = 2,
    Detailed = 3
}

const ReportModal: React.FC<Props> = (props) => {
    const { year, today } = props;
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const { open, onToggle} = useModal();
    const [currentGroupBy, setCurrentGroupBy] = useState(GroupBy.ByProject)

    const employees = useAppSelector((selector) => selector.rEmployees);
    const projects = useAppSelector((selector) => selector.eProjects);

    const groupBy = [
        { id: GroupBy.ByProject, name: 'Group by project' },
        { id: GroupBy.ByEmployee, name: 'Group by employee' },
        { id: GroupBy.Detailed, name: 'Detailed by day' },
    ]

    const visibleDays = [
        { id: 0, name: 'All' },
        { id: 1, name: 'Show only days with shifts' },
        { id: 2, name: 'Show only incorrect days' },
    ]

    const month = today.clone().add(-1, 'month')
    const initialValue: RequestReportForm = {
        dateRange: [month.format('yyyy-MM-DD'), today.format('yyyy-MM-DD')],
        groupBy: GroupBy.ByProject as number,
    };

    const validationScheme = Yup.object().shape({
        dateRange: Yup.array(Yup.string()).required(),
        groupBy: Yup.number().required()
            .test(
                'group-by',
                'Done',
                (value: number) => { setCurrentGroupBy(value as GroupBy); return true; }
            )
    })

    const downloadReport = (base64: Blob, form: RequestReportForm) => {
        const href = URL.createObjectURL(base64);
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', `Report_${form.dateRange[0]}__${form.dateRange[1]}`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href)
        return void(0)
    }

    const handleSubmit = (values: RequestReportForm) => {
        switch (values.groupBy) {
            case GroupBy.ByEmployee:
                dispatch(downloadReportByEmployee({
                    dateFrom: values.dateRange[0],
                    dateTo: values.dateRange[1],
                    employeeIds: values.employees?.map((x) => x.id),
                    projectIds: values.projects?.map((x) => x.id),
                    hideEmptyProjects: values.hideEmptyProjects
                })).unwrap().then((result) => {
                    downloadReport(result, values)
                });
                break;
            case GroupBy.ByProject:
                dispatch(downloadReportByProject({
                    dateFrom: values.dateRange[0],
                    dateTo: values.dateRange[1],
                    employeeIds: values.employees?.map((x) => x.id),
                    projectIds: values.projects?.map((x) => x.id),
                    hideEmptyProjects: values.hideEmptyProjects
                })).unwrap().then((result) => {
                    downloadReport(result, values)
                });
                break;
            case GroupBy.Detailed:
                dispatch(downloadReportDetailed({
                    dateFrom: values.dateRange[0],
                    dateTo: values.dateRange[1],
                    employeeIds: values.employees?.map((x) => x.id),
                    projectIds: values.projects?.map((x) => x.id),
                    visibleDays: values.visibleDays
                })).unwrap().then((result) => {
                    downloadReport(result, values)
                });
                break;
        }
        onToggle()
    }

    const handleClose = () => {
        setCurrentGroupBy(GroupBy.ByEmployee);
        onToggle();
    }

    return (<>
        <Tooltip title={intl.formatMessage({ id: Lx.Schedule.CREATE_REPORT })}>
            <Button variant='loadMore' className={styles.actionButton} onClick={onToggle}>
                <SVGS.AddChartIcon className={styles.fill}/>
            </Button>
        </Tooltip>
        <Box position={'absolute'}>
            {
                open && <Formik
                    initialValues={initialValue}
                    validationSchema={validationScheme}
                    validateOnBlur={true}
                    validateOnChange={true}
                    onSubmit={handleSubmit}>
                    <Form>
                        <CreateModal open={open} title={intl.formatMessage({id: Lx.General.REPORT})} onClose={handleClose}>
                            <FormDateSelector label={'Date range'} name={'dateRange'} year={{
                                id: year.year, months: year.months.map((m) => ({
                                    id: m.month, days: m.days.map((d) => ({
                                        id: d.day,
                                        type: d.isShort ? 'short' : d.isHoliday ? 'off' : 'default'
                                    }))
                                }))
                            }} />
                            <FormDropdown label={'Report type'} name={'groupBy'} items={groupBy} required />
                            { employees.length > 0 && <FormAutocomplete label={'Include Employees'} name={'employees'} placeHolder={'Start typing'} items={employees.map((x) => ({ id: x.id, name: x.name, isDefault: x.isDefault }))} /> }
                            { projects.length > 0 && <FormAutocomplete label={'Include projects'} name={'projects'} placeHolder={'Start typing'} items={projects.map((x) => ({ id: x.id, name: x.name, isDefault: x.isDefault }))} /> }
                            {
                                currentGroupBy === GroupBy.Detailed && <FormDropdown label={'Show days'} name={'visibleDays'} items={visibleDays} initialValue={{ id: 0, name: 'All' }} />
                            }
                            {
                                currentGroupBy !== GroupBy.Detailed && <FormCheckbox label={'Hide empty projects'} name={'hideEmptyProjects'} type={'checkbox'} />
                            }
                        </CreateModal>
                    </Form>
                </Formik>
            }
        </Box>
    </>)
}

export default ReportModal;