import {DateRangeFilter, PropertyFilter} from '../schedule/types';
import {store} from '../../store/store';
import timeReportReducer, {setData, updateDateRangeFilter, updatePropertyFilter} from './slice';
import { TimeReportItem } from './types';

export const updateTimeReportPropertyFilter = (filter: PropertyFilter) => {
    store.dispatch(updatePropertyFilter(filter));
}

export const updateTimeReportDateRangeFilter = (filter: DateRangeFilter) => {
    store.dispatch(updateDateRangeFilter(filter));
}

export const setTimeReportData = (data: Array<TimeReportItem>) => {
    store.dispatch(setData(data))
}

export { timeReportReducer };