import React, {ReactElement} from 'react'
import {styled} from '@mui/material';
import {selectClasses, SelectProps, SelectRootSlotProps} from '@mui/base/Select';
import {SVGS} from '../../../../../assets/images';
import Listbox, { Option } from '../../../listbox';
import {Select as BaseSelect} from '@mui/base/Select/Select';

const StyledButton = styled('button', { shouldForwardProp: () => true })(
    ({ theme }) => `
margin: 0;
padding: 8px 16px !important;
width: 100%;
position: relative;
background: #181818;
display: grid !important;
grid-template-columns: auto auto;
box-shadow: none !important;
border: none !important;
border-color: transparent !important;
border-radius 0 !important;
text-align: start;

&.${selectClasses.expanded} {
    color: #FFF;
    border-color: transparent !important;

    svg {
        path {
            fill: #FFF;
        }
    }
}

&.${selectClasses.focusVisible} {
    outline: 0;
    border-color: transparent !important;
}

svg {
    justify-self: flex-end;
    width: auto;
    path {
        fill: #FFF;
    }
}


font: inherit;
`);

const Popup = styled('div')`
  z-index: 1;
`;

const CustomButton = React.forwardRef(function CustomButton<
    TValue extends NonNullable<unknown>,
    Multiple extends boolean,
>(
    props: SelectRootSlotProps<TValue, Multiple>,
    ref: React.ForwardedRef<HTMLButtonElement>,
) {
    const { ownerState, ...other } = props;
    return (
        <StyledButton type="button" {...other} ref={ref}>
            <span>{other.children}</span>
            <SVGS.DropdownIcon/>
        </StyledButton>
    );
});

const Select = React.forwardRef(function Select<
    TValue extends NonNullable<unknown>,
    Multiple extends boolean,
>(props: SelectProps<TValue, Multiple>, ref: React.ForwardedRef<HTMLButtonElement>) {
    const slots: SelectProps<TValue, Multiple>['slots'] = {
        root: CustomButton,
        listbox: Listbox,
        popup: Popup,
        ...props.slots,
    };

    return <BaseSelect {...props} ref={ref} slots={slots}/>;
}) as <TValue extends NonNullable<unknown>, Multiple extends boolean>(
    props: SelectProps<TValue, Multiple> & React.RefAttributes<HTMLButtonElement>,
) => ReactElement;

export { Select as GridDropdown };
export { Option as GridDropdownOption };