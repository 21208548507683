import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import HttpClient from '../../../api/api';
import {Projects} from './../types';

const initialState: Projects = {
    types: [],
    items: [],
    offset: 0,
    total: 0,
    canCreateNewProject: false,
    canEditRoleForNotification: false,
    canEditProjectManager: false,
    canEditEmployees: false,
}

export const getAllProjects = createAsyncThunk(
    'projects/get',
    async (page: number) => {
        const response = await HttpClient.projectApi.getAllFullProjects<Projects>(page);
        return response.data
    }
)

const typeSplice = createSlice({
    name: 'projects',
    initialState,
    reducers: {
        clearProjects: (state) => {
            return initialState
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getAllProjects.fulfilled, (state, action) => {
            if (action.payload.offset === 0) {
                return {...state, ...action.payload}
            } else {
                state.items = [...state.items, ...action.payload.items];
                state.offset = action.payload.offset;
            }
        });
    }
})

export const { clearProjects } = typeSplice.actions

export default typeSplice.reducer;