import React from 'react';
import {initialState, TimeSheetSearchContext} from './context';

const TimeSheetSearchProvider = (props: { children: React.ReactNode }) => {
    const [state, setState] = React.useState(initialState);

    const config: TimeSheetSearchContext = {
        ...initialState,
        ...state,
        change: (value) => setState((v) => ({ ...v, value })),
    }

    return <TimeSheetSearchContext.Provider value={config}>
        {props.children}
    </TimeSheetSearchContext.Provider>
}

export const useTimeSheetSearch = (): TimeSheetSearchContext => React.useContext(TimeSheetSearchContext);

export default TimeSheetSearchProvider;