import React from 'react';
import styles from './form-dateselector.module.scss';
import DateSelector from '../../date-selector';
import {Year} from '../../../calendar/types';
import {useFormikContext} from 'formik';
import moment, { Moment } from 'moment';
import classNames from 'classnames';

interface Props {
    label: string;
    name: string;
    year?: Year;
    range?: boolean;
    required?: boolean;
    minDate?: Moment;
    maxDate?: Moment;
}

const FormDateSelector = (props: Props) => {
    const { setFieldValue, values, getFieldMeta } = useFormikContext<string[]>()
    const meta = getFieldMeta<string[]>(props.name)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const value = [moment(values[props.name], 'YYYY-MM-DD')];

    const handleChange = (values: Moment[]) => {
        setFieldValue(props.name, props.range ? values.map((x) => x.format('YYYY-MM-DD')) : values[0].format('YYYY-MM-DD'), props.required ?? false);
    }

    return (
        <label htmlFor={props.name} className={classNames({
            [styles.label]: true,
            [styles.stretch]: true,
        })}>
            {props.label} {props.required==false ? (<span className={styles.optional}>(optional)</span>) : '' }
            <DateSelector year={props.year} value={value} range={props.range} onComplete={handleChange} minDate={props.minDate} maxDate={props.maxDate} />
            {meta.error ? (<div className={styles.error}>{meta.error}</div>) : null}
        </label>
    )
}

export default FormDateSelector