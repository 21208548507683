import PopupMenuActions from '../../../../shared/popup-menu';
import { PopupMenuAction } from '../../../../shared/popup-menu/types';
import {SVGS} from '../../../../../assets/images';
import React, { useEffect, useState } from 'react';
import {TimeSheetItemStatus, TimeSheetItemType} from '../../../../../features/schedule/types';
import {IntlShape} from 'react-intl';
import {Lx} from '../../../../../i18n/consts';

interface Props {
    intl: IntlShape;
    status: TimeSheetItemStatus;
    approvableHours: number;
    billableHours: number;
    approvedHours: number;
    billedHours: number;
    onAddNewShiftClick?: () => Promise<void>;
    onApproveClick?: () => Promise<void>;
    onDisaproveClick?: () => Promise<void>;
    onBillClick?: () => Promise<void>;
    onCancelBillClick?: () => Promise<void>;
    disableApprove?: boolean;
    disableBillable?: boolean;
}

const ToolActionMenu: React.FC<Props> = (props) => {
    const { intl, status, approvableHours, billableHours, approvedHours, billedHours, disableBillable, disableApprove } = props;
    const [actions, setActions] = useState<Array<PopupMenuAction>>([]);

    const loadActions = () => {
        const temp: Array<PopupMenuAction> = [];
        if (!disableApprove) {
            if (status !== TimeSheetItemStatus.NORMAL || approvedHours > 0) {
                temp.push({
                    name: intl.formatMessage({id: Lx.Schedule.DISAPPROVE_FORMAT}, {s: approvedHours}),
                    icon: <SVGS.DisapproveIcon/>,
                    callback: () => Promise.resolve(props.onDisaproveClick?.()),
                })
            } else {
                temp.push({
                    name: intl.formatMessage({id: Lx.Schedule.APPROVE_ALL_SHIFT_FORMAT}, {s: approvableHours}),
                    icon: <SVGS.ApproveIcon/>,
                    callback: () => Promise.resolve(props.onApproveClick?.()),
                })
            }
        }
        if (!disableBillable) {
            if ((status !== TimeSheetItemStatus.NORMAL && status !== TimeSheetItemStatus.APPROVED) || billedHours > 0) {
                temp.push({
                    name: intl.formatMessage({id: Lx.Schedule.CANCEL_BILL_FORMAT}, {s: billedHours}),
                    icon: <SVGS.MoneyOffIcon/>,
                    callback: () => Promise.resolve(props.onCancelBillClick?.()),
                })
            } else {
                temp.push({
                    name: intl.formatMessage({id: Lx.Schedule.BILL_SHIFT_FORMAT}, {s: billableHours}),
                    icon: <SVGS.MoneyIcon />,
                    callback: () => Promise.resolve(props.onBillClick?.()),
                })
            }
        }
        temp.push({
            name: intl.formatMessage({id: Lx.Schedule.CREATE_NEW_TIMESHIFT_SHIFT}),
            icon: <SVGS.PlusIcon/>,
            callback: () => Promise.resolve(props.onAddNewShiftClick?.())
        })
        setActions(temp);
    }

    useEffect(() => {
        loadActions();
    }, [status])

    useEffect(() => {
        loadActions();
    }, [])

    return(<PopupMenuActions actionIcon={<SVGS.ActionIcon />} actions={actions} newGen size={'small'} />)
}

export default ToolActionMenu