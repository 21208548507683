import React, {useEffect, useState} from 'react';
import styles from './date-selector.module.scss';
import DateLabel from '../date-label';
import {PopupCalendar} from '../../calendar';
import {Year} from '../../calendar/types';
import classNames from 'classnames';
import { Moment } from 'moment';
import {SVGS} from '../../../../assets/images';

interface Props extends React.HTMLAttributes<HTMLDivElement>{
    value: Moment[]
    onComplete: (values: Moment[]) => void;
    range?: boolean;
    year?: Year;
    minDate?: Moment;
    maxDate?: Moment;
}

const DateSelector: React.FC<Props> = (props) => {
    const { year } = props;
    const [value, setValue] = useState<Moment[]>(props.value);
    const [open, setOpen] = useState(false);

    const handleOnComplete = (value: Moment[]) => {
        setValue(value);
        props.onComplete(value);
        setOpen(false);
    }
    
    const InputRenderer = () => {
        return (<div className={classNames({[styles.selector]: true, [styles.active]: open})} {...props}
            onClick={() => !open && setOpen(true)}>
            <div className={styles.label}>
                <DateLabel value={value[0]} type={'day'} small/>
                {
                    value.length > 1 && value[0] !== value[1] && <>
                        <span className={styles.dash}>–</span>
                        <DateLabel value={value[1]} type={'day'} small/>
                    </>
                }
            </div>
            <div className={classNames({[styles.actionFakeButton]: true, [styles.active]: open})}>
                <SVGS.CalendarIcon/>
            </div>
        </div>)
    };

    return (
        <>
            {
                !props.range && <PopupCalendar
                    year={year}
                    minDate={props.minDate}
                    maxDate={props.maxDate}
                    view={'month'}
                    state={{open: open, setOpen: setOpen}}
                    onCalendarDateClick={(value) => handleOnComplete([value])}
                    value={value[0]}
                    renderer={<InputRenderer />}
                    disablePortal small/>
            }
            {
                !!props.range && <PopupCalendar range
                    year={year}
                    view={'month'}
                    minDate={props.minDate}
                    maxDate={props.maxDate}
                    state={{open: open, setOpen: setOpen}}
                    value={value}
                    onRangeComplete={handleOnComplete}
                    renderer={<InputRenderer />}
                    disablePortal small/>
            }
        </>)
}

export default DateSelector