import {styled} from '@mui/material';
import React, {ReactElement} from 'react';
import {TimeSheetGroupType} from './../../../../../features/schedule/types';
import {SelectProps, SelectRootSlotProps} from '@mui/base/Select';
import {SVGS} from './../../../../../assets/images';
import {Select as BaseSelect} from '@mui/base/Select/Select';
import Listbox, { Option } from './../../../../../components/shared/listbox'

const StyledButton = styled('button', { shouldForwardProp: () => true })(
    ({ theme }) => `
  margin: 0;
  background: none;
  border: none;
  width: max-content;
  padding: 0;
  position: relative;
  flex: 1 0 50%;
  box-sizing: border-box;
  gap: 0.95rem; 
  display: flex;
  align-items: center;
  box-shadow: none !important;
  white-space: nowrap;
  
  & div {
    display: flex;
    gap: 8px;
    align-items: center;
    color: #FFF;
    
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 40.5px */ 
  }
  
  & svg {
    path {
        fill: #FFF
    }
  }
  
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  `,
);


const Popup = styled('div')`
  z-index: 1;
`;

const CustomButton = React.forwardRef(function CustomButton<
    TValue extends NonNullable<unknown>,
    Multiple extends boolean,
>(
    props: SelectRootSlotProps<TValue, Multiple>,
    ref: React.ForwardedRef<HTMLButtonElement>,
) {
    const { ownerState, ...other } = props;
    return (
        <StyledButton type="button" {...other} ref={ref}>
            <div>
                {other.children}
                <SVGS.KeyboardArrowDownIcon />
            </div>
        </StyledButton>
    );
});

const Select = React.forwardRef(function Select<
    TValue extends NonNullable<unknown>,
    Multiple extends boolean,
>(props: SelectProps<TValue, Multiple>, ref: React.ForwardedRef<HTMLButtonElement>) {
    const slots: SelectProps<TValue, Multiple>['slots'] = {
        root: CustomButton,
        listbox: Listbox,
        popup: Popup,
        ...props.slots,
    };

    return <BaseSelect {...props} ref={ref} slots={slots} />;
}) as <TValue extends NonNullable<unknown>, Multiple extends boolean>(
    props: SelectProps<TValue, Multiple> & React.RefAttributes<HTMLButtonElement>,
) => ReactElement;

interface Props {
    value: TimeSheetGroupType;
    onChange: (value: TimeSheetGroupType | null) => void;
}

const ViewSelector: React.FC<Props> = (props) => {

    return(<Select defaultValue={TimeSheetGroupType.EMPLOYEEVIEW}
        value={props.value} multiple={false} 
        onChange={(event, value) => props.onChange(value)}>
        <Option value={TimeSheetGroupType.EMPLOYEEVIEW}>Employees</Option>
        <Option value={TimeSheetGroupType.PROJECTVIEW}>Projects and Absences</Option>
    </Select>)
}
export default ViewSelector;