import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import { AutoCompleteItemValue } from '../../components/shared/nextgen/selector/types';
import {DateRangeFilter, PropertyFilter, TimeSheetItem, TimeSheetItemStatus} from './types';
import {Lx} from '../../i18n/consts';

interface State {
    filter: {
        dateRangeFilter: DateRangeFilter;
        propertyFilter: PropertyFilter;
    },
    data: Array<TimeSheetItem>
}

export const initialStatuses: AutoCompleteItemValue[] = [
    { id: 0, name: Lx.Schedule.REGULAR_HOURS, originalId: TimeSheetItemStatus.NORMAL },
    { id: 1, name: Lx.Schedule.APPROVED_HOURS, originalId: TimeSheetItemStatus.APPROVED },
    { id: 2, name: Lx.Schedule.BILLABLE_HOURS, originalId: TimeSheetItemStatus.BILLABLE },
]

const initialState: State = {
    filter: {
        dateRangeFilter: {
            startDate: '',
            endDate: ''
        },
        propertyFilter: {
            selectedEmployees: [],
            selectedProjects: [],
            selectedShiftTypes: [],
            selectedAbsenceTypes: [],
            selectedStatuses: []
        }
    },
    data: []
}

const timeSheetSlice = createSlice({
    name: 'timesheet',
    initialState,
    reducers: {
        clearData: (state) => {
            state.data = []
        },
        setData: (state, action: PayloadAction<Array<TimeSheetItem>>) => {
            state.data = action.payload
        },
        updateDateRangeFilter: (state, action: PayloadAction<DateRangeFilter>) => {
            state.filter.dateRangeFilter = action.payload;
        },
        updatePropertyFilter: (state, action: PayloadAction<PropertyFilter>) => {
            state.filter.propertyFilter = action.payload;
        }
    }
})

export const { updateDateRangeFilter, updatePropertyFilter, clearData, setData } = timeSheetSlice.actions;

export default timeSheetSlice.reducer;