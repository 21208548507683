import React from 'react';
import {initialState, TimeSheetGroupByContext} from './context';

const TimeSheetGroupByProvider = (props: { children: React.ReactNode }) => {
    const [state, setState] = React.useState(initialState);

    const config: TimeSheetGroupByContext = {
        ...initialState,
        ...state,
        change: (value) => setState((st) => ({...st, groupBy: value})),
    }

    return <TimeSheetGroupByContext.Provider value={config}>
        {props.children}
    </TimeSheetGroupByContext.Provider>
}

export const useTimeSheetGroupBy = (): TimeSheetGroupByContext => React.useContext(TimeSheetGroupByContext);

export default TimeSheetGroupByProvider;