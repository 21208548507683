import React, {useEffect, useState} from 'react';
import {useAppDispatch} from '../../../../hooks/hooks';
import useModal from '../../../../hooks/useModal';
import {Form, Formik} from 'formik';
import {Shift} from '../../../../features/shifts/types';
import {createShift} from '../../../../features/shifts';
import CreateModal from '../../../shared/modal/create-modal';
import {Lx} from '../../../../i18n/consts';
import {FormInput} from '../../../shared/nextgen/form/form-input';
import { FormDropdown } from '../../../shared/nextgen/form/form-dropdown';
import {TiminatorType} from '../../../../features/types/types';
import {Employee} from '../../../../features/employees/types';
import {getEmployee} from '../../../../features/employees';
import {getProject} from '../../../../features/projects';
import moment from 'moment';
import * as Yup from 'yup';
import { Box } from '@mui/material';
import {useIntl} from 'react-intl';
import {CreateShift} from '../../../../features/schedule/types';
import { FormTextarea } from '../../../shared/nextgen/form/form-textarea';
import FormDateSelector from '../../../shared/nextgen/form/form-date-selector';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {
    create: CreateShift;
    shiftTypes: Array<TiminatorType>;
    onCreate: () => void;
}

interface CreateForm {
    employee: number;
    date: string
    shiftType: number;
    project: number;
    hours: number;
    comment?: string;
    note?: string;
}

const CreateShiftModal: React.FC<Props> = (props) => {
    const { create, onCreate, shiftTypes } = props;
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const { open, onToggle , openModal} = useModal();
    const today = moment();
    const [employee, setEmployee] = useState<Employee>({
        admin: false,
        canEditAdminLabel: false,
        canEditProjects: false,
        canEditRoles: false,
        email: '',
        firstName: '',
        id: 0,
        isActive: false,
        lastName: '',
        phoneNumber: '',
        position: '',
        projects: [],
        roles: []
    });

    let initialValue: CreateForm;
    if (create.state) {
        initialValue = {
            employee: create.employees[0].id,
            date: create.state.date ?? moment().format('yyyy-MM-DD'),
            shiftType: shiftTypes[0].id,
            project: 0,
            hours: 8,
        };
    } else {
        initialValue = {
            employee: create.employees[0].id,
            date: moment().format('yyyy-MM-DD'),
            shiftType: shiftTypes[0].id,
            project: 0,
            hours: 8,
        };
    }

    const validationScheme: Yup.Schema<CreateForm> = Yup.object().shape({
        employee: Yup.number()
            .required()
            .test(
                'change-employee',
                'Done',
                async (value: number) => await loadEmployeeData(value)
            ),
        date: Yup.string().required(),
        shiftType: Yup.number().required(),
        project: Yup.number().required(),
        hours: Yup.number().required().min(0.5).max(24),
        comment: Yup.string().optional(),
        note: Yup.string().optional(),
    })

    const add = async (values: CreateForm) => {
        return dispatch(getProject(values.project < 1 ? employee.projects[0].id : values.project)).unwrap().then(async (result) => {
            const shift: Shift = {
                id: 0,
                employeeId: values.employee,
                date: values.date.toString(),
                shiftType: shiftTypes.find((x) => x.id === values.shiftType)!,
                remote: false,
                isOvertime: false,
                billable: false,
                approved: false,
                hours: values.hours,
                enteredHoursString: values.hours.toString(),
                comment: values.comment ?? '',
                note: values.note ?? '',
                recurringPeriod: 0,
                project: {
                    id: result.id,
                    name: result.name,
                    isActive: result.isActive,
                    projectType: {
                        id: result.projectType.id,
                        name: result.projectType.name,
                        active: true,
                        default: result.projectType.isDefault
                    }
                },
                itemId: 0
            };
            return await dispatch(createShift(shift)).unwrap().then(() => {
                onCreate();
                onToggle();
            });
        }).catch((reason) => {
            console.error(reason)
        })
    }

    const loadEmployeeData = async (employeeId: number): Promise<boolean>  => {
        if (employee.id === employeeId || employeeId < 1) {
            return true;
        }
        await dispatch(getEmployee(employeeId)).unwrap().then((result) => {
            setEmployee(result)
            return true;
        })
        return true
    }

    useEffect(() => {
        dispatch(getEmployee(create.employees[0].id)).unwrap().then((value) => {
            setEmployee(value)
            openModal();
        })
    }, []);

    return (<span>
        {
            open && <Formik
                initialValues={initialValue}
                validationSchema={validationScheme}
                validateOnBlur={true}
                validateOnChange={true}
                onSubmit={add}>
                <Form>
                    <CreateModal open={open} title={intl.formatMessage({ id: Lx.General.SHIFT })} onClose={onToggle}>
                        <FormDropdown label={intl.formatMessage({ id: Lx.General.EMPLOYEE })}
                            name={'employee'} 
                            items={create.employees}
                            required
                            placeholder={'Choose employee'}
                            selectProps={{
                                disabled: create.employees.length < 2
                            }}/> {
                            employee.id > 0 && <>
                                <Box display={'flex'} flexDirection={'row'} alignItems={'center'} gap={3} justifyContent={'stretch'}>
                                    <FormDateSelector maxDate={today} 
                                        label={intl.formatMessage({ id: Lx.Schedule.TIMESHIFT_DATE })} 
                                        name={'date'} 
                                        required />
                                    <FormDropdown label={intl.formatMessage({ id: Lx.General.SHIFT_TYPE })}
                                        name={'shiftType'}
                                        placeholder={intl.formatMessage({ id: Lx.General.SHIFT_TYPE })}
                                        initialValue={shiftTypes[0]}
                                        items={shiftTypes.map((x) => ({id: x.id, name: x.name}))}
                                        required/>
                                </Box>
                                <Box display={'flex'} flexDirection={'row'} alignItems={'center'} gap={3}>
                                    <FormDropdown label={intl.formatMessage({ id: Lx.General.PROJECT })}
                                        name={'project'} 
                                        items={employee.projects}
                                        required/>
                                    <FormInput type={'number'}
                                        max={24}
                                        min={0.5}
                                        label={intl.formatMessage({ id: Lx.Schedule.TIMESHIFT_HOURS })} name={'hours'}
                                        placeholder={intl.formatMessage({ id: Lx.Schedule.TIMESHIFT_HOURS })} required/>
                                </Box>
                                <FormTextarea label={intl.formatMessage({ id: Lx.Schedule.TIMESHIFT_NOTE })} name={'comment'}
                                    placeholder={intl.formatMessage({ id: Lx.Schedule.TIMESHIFT_NOTE })}
                                    resize={'vertical'}
                                    multiple={true}/>
                                <FormTextarea label={intl.formatMessage({ id: Lx.Schedule.TIMESHIFT_INTERNAL_NOTE })} name={'note'}
                                    placeholder={intl.formatMessage({ id: Lx.Schedule.TIMESHIFT_INTERNAL_NOTE })}
                                    resize={'vertical'}
                                    multiple={true}/>
                            </>
                        }
                    </CreateModal>
                </Form>
            </Formik>
        }
    </span>)
}

export default CreateShiftModal;