import PopupMenuActions from '../../../../shared/popup-menu';
import {SVGS} from '../../../../../assets/images';
import React from 'react';
import {TimeSheetItemStatus, TimeSheetItemType} from '../../../../../features/schedule/types';
import {IntlShape} from 'react-intl';
import {PopupMenuAction} from '../../../../shared/popup-menu/types';
import {Lx} from '../../../../../i18n/consts';

interface Props {
    intl: IntlShape;
    status: TimeSheetItemStatus;
    timeshiftType: TimeSheetItemType,
    hours: number;
    onStatusChangeClick: (status: TimeSheetItemStatus, previous: TimeSheetItemStatus) => void;
    onAddNewShiftClick: () => void;
    onEditTimeClick: () => void;
    onDeleteItemClick?: () => void;
}

const TimeSheetActionMenu: React.FC<Props> = (props) => {
    const { intl, status, timeshiftType, hours, onStatusChangeClick } = props;
    const isShift = timeshiftType === TimeSheetItemType.SHIFT
    const actions: PopupMenuAction[] = [];

    if (isShift) {
        actions.push({
            name: status !== TimeSheetItemStatus.NORMAL ?
                intl.formatMessage({id: Lx.Schedule.DISAPPROVE_SHIFT_FORMAT}, {s: hours})
                : intl.formatMessage({id: Lx.Schedule.APPROVE_ALL_SHIFT_FORMAT}, {s: hours}),
            icon: status !== TimeSheetItemStatus.NORMAL ? <SVGS.DisapproveIcon/> : <SVGS.ApproveIcon/>,
            callback: () => {
                onStatusChangeClick(status === TimeSheetItemStatus.NORMAL ? TimeSheetItemStatus.APPROVED : TimeSheetItemStatus.NORMAL, status);
            },
        })
        actions.push({
            name: status !== TimeSheetItemStatus.NORMAL && status !== TimeSheetItemStatus.APPROVED ?
                intl.formatMessage({id: Lx.Schedule.CANCEL_BILL_FORMAT}, {s: hours})
                : intl.formatMessage({id: Lx.Schedule.BILL_SHIFT_FORMAT}, {s: hours}),
            icon: status !== TimeSheetItemStatus.NORMAL && status !== TimeSheetItemStatus.APPROVED ? <SVGS.MoneyOffIcon/> :
                <SVGS.MoneyIcon />,
            callback: () => {
                onStatusChangeClick(status !== TimeSheetItemStatus.BILLABLE ? TimeSheetItemStatus.BILLABLE : TimeSheetItemStatus.APPROVED, status);
            },
        })
    }
    actions.push({
        name: intl.formatMessage({id: Lx.Schedule.CREATE_NEW_TIMESHIFT_SHIFT_TO_THIS_DAY}),
        icon: <SVGS.PlusIcon/>,
        callback: props.onAddNewShiftClick
    })

    actions.push({
        name: intl.formatMessage({ id: isShift ? Lx.Schedule.EDIT_TIMESHIFT_SHIFT : Lx.Schedule.EDIT_TIMESHIFT_ABSENCE }),
        icon: <SVGS.EditIcon />,
        callback: props.onEditTimeClick
    })

    if (props.onDeleteItemClick) {
        actions.push({
            name: intl.formatMessage({ id: isShift ? Lx.Schedule.DELETE_TIMESHIFT_SHIFT: Lx.Schedule.DELETE_TIMESHIFT_ABSENCE }),
            icon: <SVGS.DeleteIcon />,
            callback: props.onDeleteItemClick
        })
    }

    return(<PopupMenuActions actionIcon={<SVGS.ActionIcon />} actions={actions} newGen size={'small'} />)
}

export default TimeSheetActionMenu