import React from 'react';

const notImplemented = () => {
    throw new Error('method not implemented');
}

export interface TimeReportSearchContext {
    value: string;
    change: (value: string) => void;
}

export const initialState: TimeReportSearchContext = {
    value: '',
    change: notImplemented,
}

export const TimeReportSearchContext = React.createContext<TimeReportSearchContext>(initialState);