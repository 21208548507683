import {Shift} from '../../../features/shifts/types';
import {Absence} from '../../../features/absences/types';
import {REmployees} from '../../../features/roles/types';
import {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from '../../../hooks/hooks';
import {getShifts} from '../../../features/shifts';
import {clearAbsences, getAbsences} from '../../../features/absences';
import {AutoCompleteItemValue} from '../../../components/shared/nextgen/selector/types';
import { setTimeReportData } from '../../../features/time-report';
import { Project } from '../../../features/projects/types';
import { CreateTimeReportItemByAbsence, CreateTimeReportItemByShift, TimeReportItem } from '../../../features/time-report/types';
import { getAbsenceStatus, getShiftStatus } from '../../../helpers/ScheduleHelper';

const convertShiftsToTimeReportItem = (employee: REmployees, project: Project[], shifts: Shift[]): TimeReportItem[] => {
    return shifts.map((shift) => {
        const status = getShiftStatus(shift);
        const client = project.find((x) => x.id === shift.project.id)?.client;
        return CreateTimeReportItemByShift(employee.id, employee.name, status, shift, client?.id, client?.name)});
}

const convertAbsenceToTimeReportItem = (employee: REmployees, absences: Absence[]): TimeReportItem[] => {
    return absences.map((absence) => {
        const status = getAbsenceStatus(absence.approved);
        return CreateTimeReportItemByAbsence(employee.id, employee.name, status, absence)});
}

const useTimeReportView = () =>
{
    const dispatch = useAppDispatch();
    const [initial, setInitial] = useState<boolean>(true)
    const [loading, setLoading] = useState<boolean>(false);
    const shifts = useAppSelector((selector) => selector.shifts.items);
    const absences = useAppSelector((selector) => selector.absences.items);
    const employees = useAppSelector((selector) => selector.rEmployees);
    const fullProjects = useAppSelector((selector) => selector.projects.items);
    const filter = useAppSelector((selector) => selector.timesheet.filter);

    const load = (
        fromDate: string,
        toDate: string,
        employees: AutoCompleteItemValue[],
        projects: AutoCompleteItemValue[],
    ): Promise<unknown> => {
        const promises: Array<Promise<unknown>> = [];

        promises.push(dispatch(getShifts({
            dateFrom: fromDate,
            dateTo: toDate,
            employees: employees?.map((x) => (x.originalId as number)) ?? [],
            projects: projects?.map((x) => (x.originalId as number)) ?? [],
            types: []
        })));

        if (projects.length > 0) {
            dispatch(clearAbsences());
        } else {
            promises.push(dispatch(getAbsences({
                dateFrom: fromDate,
                dateTo: toDate,
                employees: employees?.map((x) => (x.originalId as number)) ?? [],
                types: []
            })));
        }

        return Promise.all(promises);
    }

    useEffect(() => {
        if (filter.dateRangeFilter.startDate ==='' || filter.dateRangeFilter.endDate === '') {
            return;
        }

        setLoading(true);

        if (initial) {
            setInitial(false);
            load(filter.dateRangeFilter.startDate,
                filter.dateRangeFilter.endDate,
                filter.propertyFilter.selectedEmployees,
                filter.propertyFilter.selectedProjects).then(() => setLoading(false));
            return;
        }

        load(
            filter.dateRangeFilter.startDate,
            filter.dateRangeFilter.endDate,
            filter.propertyFilter.selectedEmployees,
            filter.propertyFilter.selectedProjects).then(() => setLoading(false));
    }, [employees, filter]);

    useEffect(() => {
        if (!shifts && !absences) {
            return;
        }

        const items: TimeReportItem[] = employees.map((e) => {
            const employeeShifts = convertShiftsToTimeReportItem(e, fullProjects, shifts.filter((s) => s.employeeId == e.id));
            const employeeAbsences = convertAbsenceToTimeReportItem(e, absences.filter((a) => a.employeeId == e.id));
            return [...employeeShifts, ...employeeAbsences];
        }).flat(1);

        const init: TimeReportItem[] = [];
        
        //grouping
        const groupedTimeshifts = items.reduce((acum, item) => {
            const temp = acum.filter(value => value.id == item.id && value.projectId == item.projectId);

            if (temp.length > 0)
            {
                const groupedValue = temp[0];
                groupedValue.workHours += item.workHours;
                groupedValue.approvedHours += item.approvedHours;
                groupedValue.billedHours += item.billedHours;
                groupedValue.overtimes += item.overtimes;
                groupedValue.absenceHours += item.absenceHours;
                return acum;
            } else {
                acum.push(item);
                return acum;
            }
        },
        init);
    
        setTimeReportData(groupedTimeshifts);

        
    }, [shifts, absences]);

    return { data: useAppSelector((store) => store.timereport.data), loading };
}

export default useTimeReportView;