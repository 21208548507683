import React, {useEffect} from 'react';
import {useIntl} from 'react-intl';
import SimplePageWrapper from '../../components/shared/simple-page-wrapper';
import {useAppDispatch, useAppSelector} from '../../hooks/hooks';
import moment from 'moment';
import {getAllEmployees} from '../../features/employees/short';
import {getProjects} from '../../features/projects/filter';
import {getAllProjects} from '../../features/projects/all';
import {getWorkCalendar, getWorkCalendars} from '../../features/settings/workcalendar';
import useTimeReportView from './hooks/useTimeReportView';
import TimeReportGrid from './components/time-report-grid';
import { TimeReportTopBar } from './components/top-bar';
import TimeReportSearchProvider from './contexts/search';
import {setIndicatorLoading} from '../../features/indicator';

export default function TimeReportPage() {
    const today = moment();
    const intl = useIntl();
    const dispatch = useAppDispatch();

    // selector
    const employees = useAppSelector((selector) => selector.rEmployees);
    const projects = useAppSelector((selector) => selector.eProjects);
    const { workCalendars, year } = useAppSelector((selector) => selector.workCalendar);
    const { data, loading: dataLoading } = useTimeReportView();
    
    const loading = employees.length < 1 || projects.length < 1 || workCalendars.length < 1 || !year;

    useEffect(() => {
        dispatch(getAllEmployees());
        dispatch(getProjects());
        dispatch(getAllProjects(0));
        dispatch(getWorkCalendars()).unwrap().then((value) => {
            const primaryCalendar = value[0]!;
            const year = parseInt(today.format('yyyy'));
            dispatch(getWorkCalendar({ workCalendarId: primaryCalendar?.id ?? 0, year: year }))
        });
    }, []);

    useEffect(() => {
        setIndicatorLoading(dataLoading);
    }, [dataLoading]);

    return <SimplePageWrapper>
        <TimeReportSearchProvider>
            {/* HEADER */}
            {
                <TimeReportTopBar 
                    loading={loading} 
                    year={year} 
                    today={today}
                    items={data} />
            }
            {/* BODY */}
            {
                <TimeReportGrid data={data} intl={intl} />
            }
        </TimeReportSearchProvider>
    </SimplePageWrapper>
};