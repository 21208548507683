import { Absence } from '../absences/types';
import { TimeSheetItemStatus, TimeSheetItemType } from '../schedule/types';
import { Shift } from '../shifts/types';

export type TimeReportItem = {
    id: number;
    employeeName: string;
    projectId: number;
    projectName: string;
    clientId: number;
    clientName: string;
    status: TimeSheetItemStatus;
    timeshiftTypeId: number;
    timeshiftType: TimeSheetItemType;
    isOvertime: boolean;
    workHours: number;
    approvedHours: number;
    billedHours: number;
    absenceHours: number;
    overtimes: number;
}

export function CreateTimeReportItemByShift(
    employeeId: number, 
    employeeName: string, 
    status: TimeSheetItemStatus, 
    shift: Shift, 
    clientId?: number, 
    clientName?: string): TimeReportItem {
    return ({
        id: employeeId,
        employeeName: employeeName,
        projectId: shift.project.id,
        projectName: shift.project.name,
        clientId: clientId ?? 0,
        clientName: clientName ?? `Client of project [${shift.project.id}]`,
        workHours: shift.hours,
        status: status,
        timeshiftTypeId: shift.shiftType.id,
        timeshiftType: TimeSheetItemType.SHIFT,
        isOvertime: shift.isOvertime,
        approvedHours: status == TimeSheetItemStatus.APPROVED || status == TimeSheetItemStatus.BILLABLE ? shift.hours : 0,
        billedHours: status == TimeSheetItemStatus.BILLABLE ? shift.hours : 0,
        absenceHours: 0,
        overtimes: shift.isOvertime ? shift.hours : 0
    });
}

export function CreateTimeReportItemByAbsence(
    employeeId: number, 
    employeeName: string, 
    status: TimeSheetItemStatus, 
    absence: Absence): TimeReportItem {
    return ({
        id: employeeId,
        employeeName: employeeName,
        projectId: absence.absenceType.id,
        projectName: absence.absenceType.name,
        clientId: 0,
        clientName: '',
        workHours: 0,
        status: status,
        timeshiftTypeId: absence.absenceType.id,
        timeshiftType: TimeSheetItemType.ABSENCE,
        isOvertime: false,
        approvedHours: status == TimeSheetItemStatus.APPROVED ? absence.hours : 0,
        billedHours: 0,
        absenceHours: absence.hours,
        overtimes: 0
    });
}

export function GetGroupedWorkHours(items: TimeReportItem[]): number {
    return items.reduce((sum, a) => sum + a.workHours, 0)
}

export function GetGroupedAbsenceHours(items: TimeReportItem[]): number {
    return items.reduce((sum, a) => sum + a.absenceHours, 0)
}

export function GetGroupedApprovedHours(items: TimeReportItem[]): number {
    return items.reduce((sum, a) => sum + a.approvedHours, 0)
}

export function GetGroupedBilledHours(items: TimeReportItem[]): number {
    return items.reduce((sum, a) => sum + a.billedHours, 0)
}

export function GetGroupedOvertimes(items: TimeReportItem[]): number {
    return items.reduce((sum, a) => sum + a.overtimes, 0)
}