import Box from '@mui/material/Box';
import React from 'react';
import styles from './page-wrapper.module.scss'
import {Backdrop, CircularProgress} from '@mui/material';
import {useAppSelector} from '../../../hooks/hooks';

export default function SimplePageWrapper(props: { children: any }) {
    const loading = useAppSelector((selector) => selector.indicator.loading);
    return (<><Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
    >
        <CircularProgress color="inherit" />
    </Backdrop>
    <Box className={styles.pageWrapper}>
        <div className={styles.content}>
            {props.children}
        </div>
    </Box>
    </>)
}