import React, { FC } from 'react'
import { SVGS } from '../../../../assets/images';
import { BaseMenuItem } from '../menu-item';
import styles from './hide-button.module.scss';
import {Lx} from '../../../../i18n/consts';

interface Props {
    open: boolean;
    toggle: () => void;
}


const HideButton: FC<Props> = (props) => {
    const { open, toggle } = props;

    return(<BaseMenuItem name={Lx.General.HIDE_MENU} open={open} defaultSvg={<SVGS.UnfoldIcon />} secondarySvg={<SVGS.FoldIcon />} callback={toggle} />)
}

export default HideButton;