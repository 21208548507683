import {Shift} from '../../../features/shifts/types';
import {Absence} from '../../../features/absences/types';
import {REmployees} from '../../../features/roles/types';
import {useEffect, useState} from 'react';
import {TimeSheetItem, TimeSheetItemStatus, TimeSheetItemType} from '../../../features/schedule/types';
import {useAppDispatch, useAppSelector} from '../../../hooks/hooks';
import {clearShifts, getShifts} from '../../../features/shifts';
import {clearAbsences, getAbsences} from '../../../features/absences';
import {AutoCompleteItemValue} from '../../../components/shared/nextgen/selector/types';

import {v4 as uuidv4} from 'uuid';
import { getAbsenceStatus, getShiftStatus } from '../../../helpers/ScheduleHelper';

const convertShiftsToItemView = (e: REmployees, shifts: Shift[]): TimeSheetItem[] => {
    return shifts.map((s) => ({
        id: uuidv4(),
        employeeId: e.id,
        employeeName: e.name,
        targetId: s.project.id,
        targetName: s.project.name,
        date: s.date,
        note: s.comment,
        hours: s.hours,
        status: getShiftStatus(s),
        internalNote: s.note,
        timeshiftTypeId: s.shiftType.id,
        timeshiftType: TimeSheetItemType.SHIFT,
        itemId: s.id
    }))
}

const convertAbsenceToItemView = (e: REmployees, absences: Absence[]): TimeSheetItem[] => {
    return absences.map((a) => ({
        id: uuidv4(),
        employeeId: e.id,
        employeeName: e.name,
        targetId: a.absenceType.id,
        targetName: a.absenceType.name,
        date: a.date,
        note: a.comment,
        hours: a.hours,
        status: getAbsenceStatus(a.approved),
        internalNote: a.note,
        timeshiftTypeId: a.absenceType.id,
        timeshiftType: TimeSheetItemType.ABSENCE,
        itemId: a.id
    }))
}

const useTimeSheetView = () =>
{
    const dispatch = useAppDispatch();
    const [initial, setInitial] = useState<boolean>(true)
    const [loading, setLoading] = useState<boolean>(true);
    const employees = useAppSelector((selector) => selector.rEmployees);
    const projects = useAppSelector((selector) => selector.eProjects);
    const shifts = useAppSelector((selector) => selector.shifts.items);
    const absences = useAppSelector((selector) => selector.absences.items);
    const filter = useAppSelector((selector) => selector.timesheet.filter);
    const [data, setData] = useState<TimeSheetItem[]>([]);

    const load = (
        fromDate: string,
        toDate: string,
        employees: AutoCompleteItemValue[],
        projects: AutoCompleteItemValue[],
        shiftTypes?: AutoCompleteItemValue[],
        absenceTypes?: AutoCompleteItemValue[]
    ): Promise<unknown> => {
        const promises: Array<Promise<unknown>> = [];
        if ((absenceTypes && absenceTypes.length > 0) && (shiftTypes && shiftTypes.length < 1) && projects.length < 1) {
            dispatch(clearShifts())
        } else {
            promises.push(dispatch(getShifts({
                dateFrom: fromDate,
                dateTo: toDate,
                employees: employees?.map((x) => (x.originalId as number)) ?? [],
                projects: projects?.map((x) => (x.originalId as number)) ?? [],
                types: shiftTypes?.map((x) => (x.originalId as number)) ?? []
            })))
        }
        if ((projects.length > 0 || (shiftTypes && shiftTypes.length > 0)) && (absenceTypes && absenceTypes.length < 1)) {
            dispatch(clearAbsences())
        } else {
            promises.push(dispatch(getAbsences({
                dateFrom: fromDate,
                dateTo: toDate,
                employees: employees?.map((x) => (x.originalId as number)) ?? [],
                types: absenceTypes?.map((x) => (x.originalId as number)) ?? []
            })))
        }

        return Promise.all(promises);
    }

    const containsStatus = (statuses: AutoCompleteItemValue[], status: TimeSheetItemStatus): boolean =>
        !!statuses.find((x) => x.originalId === status);

    useEffect(() => {
        if (filter.dateRangeFilter.startDate ==='' || filter.dateRangeFilter.endDate === '') {
            return;
        }

        setLoading(true);

        if (initial) {
            setInitial(false);
            load(filter.dateRangeFilter.startDate,
                filter.dateRangeFilter.endDate,
                filter.propertyFilter.selectedEmployees,
                filter.propertyFilter.selectedProjects,
                filter.propertyFilter?.selectedShiftTypes,
                filter.propertyFilter.selectedAbsenceTypes).then(() => setLoading(false));
            return;
        }

        load(
            filter.dateRangeFilter.startDate,
            filter.dateRangeFilter.endDate,
            filter.propertyFilter.selectedEmployees,
            filter.propertyFilter.selectedProjects,
            filter.propertyFilter.selectedShiftTypes,
            filter.propertyFilter.selectedAbsenceTypes).then(() => setLoading(false));
    }, [employees, filter]);

    useEffect(() => {
        if (!shifts && !absences) {
            return;
        }
        const statusFilter = filter.propertyFilter.selectedStatuses ?? [];
        const timesheetItems: TimeSheetItem[] = employees.map((e) => {
            const employeeShifts = convertShiftsToItemView(e, shifts.filter((s) => s.employeeId == e.id));
            const employeeAbsences = convertAbsenceToItemView(e, absences.filter((a) => a.employeeId == e.id));

            if (employeeShifts.length < 1 && employeeAbsences.length < 1) {
                return [
                    {
                        id: '',
                        employeeId: e.id,
                        employeeName: e.name,
                        date: '',
                        targetName: '',
                        targetId: 0,
                        note: '',
                        internalNote: '',
                        hours: 0,
                        status: TimeSheetItemStatus.NORMAL,
                        timeshiftTypeId: 0,
                        timeshiftType: TimeSheetItemType.SHIFT,
                        itemId: 0
                    }
                ]
            }

            return [...employeeShifts, ...employeeAbsences];
        }).flat(1).map((x) => ({...x}));
        projects.forEach((x) => {
            if (!timesheetItems.find((i) => i.targetId === x.id && i.timeshiftType === TimeSheetItemType.SHIFT)) {
                timesheetItems.push({
                    id: '',
                    employeeId: 0,
                    employeeName: '',
                    date: '',
                    targetName: x.name,
                    targetId: x.id,
                    note: '',
                    internalNote: '',
                    hours: 0,
                    status: TimeSheetItemStatus.NORMAL,
                    timeshiftTypeId: 0,
                    timeshiftType: TimeSheetItemType.SHIFT,
                    itemId: 0
                })
            }
        })

        const startDate = new Date(filter.dateRangeFilter.startDate).getTime();
        const endDate = new Date(filter.dateRangeFilter.endDate).getTime();

        setData(timesheetItems
            .filter((x) => {
                if (x.date === '') {
                    return true;
                }
                const date = new Date(x.date).getTime();
                return date >= startDate && date <= endDate;
            })
            .filter((x) =>
                (filter.propertyFilter.selectedEmployees.length > 0 ? filter.propertyFilter.selectedEmployees.findIndex((y) => y.originalId === x.employeeId) !== -1 : true)
                && (filter.propertyFilter.selectedProjects.length > 0 ? x.timeshiftType === TimeSheetItemType.SHIFT && filter.propertyFilter.selectedProjects.findIndex((y) => y.originalId === x.targetId) !== -1 : true)
                && (filter.propertyFilter.selectedShiftTypes && filter.propertyFilter.selectedShiftTypes.length > 0 ? x.timeshiftType === TimeSheetItemType.SHIFT && filter.propertyFilter.selectedShiftTypes.findIndex((y) => y.originalId === x.timeshiftTypeId) !== -1 : true)
                && (filter.propertyFilter.selectedAbsenceTypes && filter.propertyFilter.selectedAbsenceTypes.length > 0 ? x.timeshiftType === TimeSheetItemType.ABSENCE && filter.propertyFilter.selectedAbsenceTypes.findIndex((y) => y.originalId === x.timeshiftTypeId) !== -1 : true))
            .filter((x) =>
                statusFilter.length > 0 ? (x.status === TimeSheetItemStatus.NORMAL && containsStatus(statusFilter, TimeSheetItemStatus.NORMAL))
                    || (containsStatus(statusFilter, TimeSheetItemStatus.APPROVED) && (x.status === TimeSheetItemStatus.APPROVED
                        || x.status === TimeSheetItemStatus.BILLABLE))
                    || (x.status === TimeSheetItemStatus.BILLABLE && containsStatus(statusFilter, TimeSheetItemStatus.BILLABLE)) : true));
    }, [shifts, absences, filter.propertyFilter.selectedStatuses]);

    return { data: data, loading };
}

export default useTimeSheetView;