import { REmployees } from '../roles/types';
import {AutoCompleteItemValue} from '../../components/shared/nextgen/selector/types';

export enum TimeSheetGroupType {
    EMPLOYEEVIEW = 0,
    PROJECTVIEW = 1
}

export enum TimeSheetItemStatus {
    NORMAL = 0,
    APPROVED = 1,
    BILLABLE = 2
}

export enum TimeSheetItemType {
    SHIFT = 0,
    ABSENCE = 1
}

export type TimeSheetItem = {
    id: string;
    employeeId: number;
    employeeName: string;
    date: string;
    targetName: string;
    targetId: number;
    note: string;
    internalNote: string;
    hours: number;
    status: TimeSheetItemStatus;
    timeshiftTypeId: number;
    timeshiftType: TimeSheetItemType;
    itemId: number;
}

export type Hours = {
    totalHours: number;
    approved: number;
    billed: number;
    workHours?: number;
    absenceHours?: number;
}

export type TimeSheetType = {
    id: number;
    timeSheetType: TimeSheetItemType;
}

export type ScheduleViewStatusFilter = {
    normal: boolean;
    approved: boolean;
    billable: boolean
}

export type TimeSheetItemUpdateState = {
    timesheetId: number;
    timesheetItemType: TimeSheetItemType;
    previousStatus: TimeSheetItemStatus;
    newStatus: TimeSheetItemStatus;
}

export type PreEditState = {
    date?: string;
    shiftType?: number;
    project?: number;
    hours?: number;
    comment?: string;
}

export type CreateShift = {
    employees: Array<REmployees>;
    state?: PreEditState;
}

export type ScheduleAutoCompleteItemValue = {
    id: number;
    name: string;
    group?: string;
    originalId: number | string;
}

export interface DateRangeFilter {
    startDate: string;
    endDate: string;
}

export interface PropertyFilter {
    selectedEmployees: AutoCompleteItemValue[];
    selectedProjects: AutoCompleteItemValue[];
    selectedShiftTypes?: AutoCompleteItemValue[];
    selectedAbsenceTypes?: AutoCompleteItemValue[];
    selectedStatuses?: AutoCompleteItemValue[];
}