import React, {useEffect, useRef} from 'react';
import moment from 'moment';
import PopupCalendar from '../../../calendar/components/popup-calendar';
import styles from './edit-cell.module.scss';

type Moment = moment.Moment;

interface Props {
    value: string;
    onChange: (value?: string) => void;
    active: boolean;
}

const DateEditCell = (props: Props ) => {
    const { onChange, active } = props;
    const [value, setValue] = React.useState<Moment>(moment(props.value));
    const [open, setOpen] = React.useState(false);
    const today = moment().startOf('day');

    const handleChange = (value: Moment) => {
        setValue(value);
        const formatValue = value?.format('yyyy-MM-DD');
        onChange(formatValue ?? '');
    }

    useEffect(() => {
        if (!active) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            setOpen(false);
        } else {
            setOpen(true);
        }
    }, [active]);

    return (<PopupCalendar
        view={'month'}
        value={value}
        state={{ open, setOpen }}
        onCalendarDateClick={handleChange}
        placement={'bottom-start'}
        renderer={<div className={styles.date}>{value.format('L')}</div>}
        minDate={(today.clone()).add(-365, 'days')}
        maxDate={today}/>)
}

export default DateEditCell;