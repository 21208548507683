import React from 'react';
import styles from './schedule-date-selector.module.scss';
import {IconButton} from '@mui/material';
import {SVGS} from '../../../../../assets/images';
import PopupMenuActions from './../../../../../components/shared/popup-menu';
import DateLabel from './../../../../../components/shared/nextgen/date-label';
import {PopupCalendar} from './../../../../../components/shared/calendar/index';
import {Year} from '../../../../shared/calendar/types';
import moment from 'moment/moment';

interface SelectorProps {
    selectedDates: Array<moment.Moment>;
    // eslint-disable-next-line react-redux/no-unused-prop-types
    year: Year,
    // eslint-disable-next-line react-redux/no-unused-prop-types
    onRangeSelected: (range: moment.Moment[]) => void;
}

const DaySelector = (props: SelectorProps) => {


    return(<div className={styles.selector}>
        <DateLabel value={props.selectedDates[0]} type={'day'} />
        {
            props.selectedDates[0].diff(props.selectedDates[1]) !== 0  && <>
                <span className={styles.dash}>–</span>
                <DateLabel value={props.selectedDates[1]} type={'day'} />
            </>
        }
        <PopupCalendar year={props.year} view={'month'} range onRangeComplete={props.onRangeSelected} value={props.selectedDates} />
    </div>)
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
const WeekSelector = (props: SelectorProps) => {
    return(<div className={styles.selector}>
        <DateLabel value={props.selectedDates[0]} type={'week'} />
        <PopupMenuActions actionIcon={<SVGS.CalendarIcon />} actions={[]} />
    </div>)
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
const MonthSelector = (props: SelectorProps) => {
    return(<div className={styles.selector}>
        <DateLabel value={props.selectedDates[0]} type={'month'} />
        <PopupMenuActions actionIcon={<SVGS.CalendarIcon />} actions={[]} />
    </div>)
}

interface Props {
    selectedDates: Array<moment.Moment>;
    onRangeSelected: (range: moment.Moment[]) => void;
    year: Year
}

const ScheduleDateSelector: React.FC<Props> = (props) => {
    const { selectedDates, year, onRangeSelected } = props;

    const handleLeftButtonClick = () => { // move left selected date range
        const start = selectedDates[0]
        const end = selectedDates[1]
        const days = start.diff(end, 'days')
        if (days < 1) {
            const newDate = start.add(-1, 'days');
            onRangeSelected([newDate, newDate])
        }
        const range = [start.clone().add(days, 'days'), start];
        onRangeSelected(range)
    };

    const handleRightButtonClick = () => { // move right selected date range
        const start = selectedDates[0]
        const end = selectedDates[1]
        const days = end.diff(start, 'days')
        if (days < 1) {
            const newDate = end.add(1, 'days');
            onRangeSelected([newDate, newDate])
            return
        }
        const range = [end, end.clone().add(days, 'days')];
        onRangeSelected(range)
    };
    return(<div className={styles.container}>
        <IconButton className={styles.iconButton} onClick={() => handleLeftButtonClick()}><SVGS.LeftChevronIcon /></IconButton>
        <DaySelector selectedDates={selectedDates} onRangeSelected={onRangeSelected} year={year} />
        <IconButton className={styles.iconButton} onClick={() => handleRightButtonClick()}><SVGS.RightChevronIcon /></IconButton>
    </div>)
}

export default ScheduleDateSelector