import React, {useEffect, useState} from 'react';
import {useAppDispatch} from '../../../../hooks/hooks';
import useModal from '../../../../hooks/useModal';
import {Form, Formik} from 'formik';
import {Shift} from '../../../../features/shifts/types';
import {updateShift} from '../../../../features/shifts';
import {Lx} from '../../../../i18n/consts';
import {FormInput} from '../../../shared/nextgen/form/form-input';
import { FormDropdown } from '../../../shared/nextgen/form/form-dropdown';
import {TiminatorType} from '../../../../features/types/types';
import {Employee} from '../../../../features/employees/types';
import {getEmployee} from '../../../../features/employees';
import {getProject} from '../../../../features/projects';
import * as Yup from 'yup';
import { Box } from '@mui/material';
import {useIntl} from 'react-intl';
import EditModal from '../../../shared/modal/edit-modal';
import { FormTextarea } from '../../../shared/nextgen/form/form-textarea';
import FormDateSelector from '../../../shared/nextgen/form/form-date-selector';
import moment from 'moment';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {
    edit: Shift;
    shiftTypes: Array<TiminatorType>;
    onComplete: () => void;
}

interface EditForm {
    date: string;
    shiftType: number;
    project: number;
    hours: number;
    comment?: string;
    note?: string;
}

const EditShiftModal: React.FC<Props> = (props) => {
    const { edit, shiftTypes, onComplete } = props;
    const intl = useIntl();
    const today = moment()
    const dispatch = useAppDispatch();
    const { open, onToggle , openModal} = useModal();
    const [employee, setEmployee] = useState<Employee>({
        admin: false,
        canEditAdminLabel: false,
        canEditProjects: false,
        canEditRoles: false,
        email: '',
        firstName: '',
        id: 0,
        isActive: false,
        lastName: '',
        phoneNumber: '',
        position: '',
        projects: [],
        roles: []
    });

    const initialValue: EditForm = {
        date: edit.date,
        hours: edit.hours,
        project: edit.project.id,
        shiftType: edit.shiftType.id,
        comment: edit.comment,
        note: !edit.note ? '' : edit.note,
    }

    const validationScheme: Yup.Schema<EditForm> = Yup.object().shape({
        date: Yup.string().required(),
        shiftType: Yup.number().required(),
        project: Yup.number().required(),
        hours: Yup.number().required().min(0.5).max(24),
        comment: Yup.string().optional(),
        note: Yup.string().optional()
    })

    const save = async (values: EditForm) => {
        return dispatch(getProject(values.project < 1 ? employee.projects[0].id : values.project)).unwrap().then(async (result) => {
            const editShift = {...edit}
            editShift.date = values.date;
            editShift.shiftType = shiftTypes.find((st) => st.id === values.shiftType)!;
            editShift.project = {
                id: result.id,
                name: result.name,
                isActive: result.isActive,
                projectType: {
                    id: result.projectType.id,
                    name: result.projectType.name,
                    active: true,
                    default: result.projectType.isDefault
                }
            }
            editShift.hours = values.hours;
            editShift.enteredHoursString = values.hours.toString();
            editShift.comment = values.comment ?? '';
            editShift.note = values.note ?? ''

            return await dispatch(updateShift(editShift)).unwrap().then(() => {
                done();
            });
        }).catch((reason) => {
            console.error(reason)
        })
    }

    const done = () => {
        onComplete();
        onToggle();
    }

    useEffect(() => {
        dispatch(getEmployee(edit.employeeId)).unwrap().then((value) => {
            setEmployee(value)
            openModal();
        })
    }, []);

    return (<span>
        {
            open && <Formik
                initialValues={initialValue}
                validationSchema={validationScheme}
                validateOnBlur={true}
                validateOnChange={true}
                onSubmit={save}>
                <Form>
                    <EditModal open={open} title={intl.formatMessage({ id: Lx.General.SHIFT })} onClose={done}>
                        <FormDropdown label={intl.formatMessage({ id: Lx.General.EMPLOYEE })}
                            name={'employee'}
                            required
                            initialValue={{ id: employee.id, name: `${employee.firstName} ${employee.lastName}` }}
                            placeholder={'Choose employee'}
                            items={[{ id: employee.id, name: `${employee.firstName} ${employee.lastName}` }]}
                            selectProps={{
                                disabled: true
                            }}/>
                        {
                            employee.id > 0 && <>
                                <Box display={'flex'} flexDirection={'row'} alignItems={'center'} gap={'24px'} justifyContent={'stretch'}>
                                    <FormDateSelector maxDate={today} label={intl.formatMessage({ id: Lx.Schedule.TIMESHIFT_DATE })} name={'date'} required />
                                    <FormDropdown label={intl.formatMessage({ id: Lx.General.SHIFT_TYPE })}
                                        name={'shiftType'}
                                        placeholder={intl.formatMessage({ id: Lx.General.SHIFT_TYPE })}
                                        initialValue={{ id: edit.shiftType.id, name: edit.shiftType.name }}
                                        items={shiftTypes.map((x) => ({id: x.id, name: x.name}))}
                                        required/>
                                </Box>
                                <Box display={'flex'} flexDirection={'row'} alignItems={'center'} gap={'24px'}>
                                    <FormDropdown label={intl.formatMessage({ id: Lx.General.PROJECT })}
                                        name={'project'}
                                        initialValue={{ id: edit.project.id, name: edit.project.name }}
                                        items={employee.projects}
                                        required/>
                                    <FormInput type={'number'}
                                        max={24}
                                        min={0.5}
                                        label={intl.formatMessage({ id: Lx.Schedule.TIMESHIFT_HOURS })} name={'hours'}
                                        placeholder={intl.formatMessage({ id: Lx.Schedule.TIMESHIFT_HOURS })} required/>
                                </Box>
                                <FormTextarea label={intl.formatMessage({ id: Lx.Schedule.TIMESHIFT_NOTE })} name={'comment'}
                                    placeholder={intl.formatMessage({ id: Lx.Schedule.TIMESHIFT_NOTE })} resize={'vertical'}/>
                                <FormTextarea label={intl.formatMessage({ id: Lx.Schedule.TIMESHIFT_INTERNAL_NOTE })} name={'note'}
                                    placeholder={intl.formatMessage({ id: Lx.Schedule.TIMESHIFT_INTERNAL_NOTE })} resize={'vertical'}/>
                            </>
                        }
                    </EditModal>
                </Form>
            </Formik>
        }
    </span>)
}

export default EditShiftModal;