import {Button, styled} from '@mui/material'
import classNames from 'classnames';
import React from 'react'
import style from './day-icon.module.scss'

interface Props {
    day: number,
    type: 'normal' | 'info' | 'warning',
    small: boolean,
    onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined,
    disableClick?: boolean;
    disabled?: boolean;
}

export default function DayIcon(props: Props) {
    const { day, type, disableClick, onClick, small, disabled } = props;

    const DayIconButton = styled(Button)({
        boxShadow: 'none',
        textTransform: 'none',
        fontWeight: 400,
        display: 'flex',
        justifyContent: 'center',
        padding: 0,
        width: 40,
        height: 40,
        minHeight: 0,
        minWidth: 0,
        borderRadius: 8,
        pointerEvents: disableClick ? 'none' : 'auto',
        fontFamily: [
            'Roboto',
            'sans-serif'
        ].join(','),
        '&.small': {
            width: 32,
            height: 32,
        },
        ':disabled': {
            color: '#FFF'
        }
    })

    return <DayIconButton className={classNames({
        [style.dayIcon]: true,
        [style.info]: props.type == 'info',
        [style.warning]: props.type == 'warning',
        ['small']: small
    })} onClick={onClick} disabled={disabled}>
        {day}
    </DayIconButton>
}