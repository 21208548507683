import React from 'react';
import styles from './date-label.module.scss';
import moment from 'moment';
import classNames from 'classnames';

type DateType = 'day' | 'week' | 'month';

const handleDateString = (textType: DateType, dateString?: string | moment.Moment) => {
    const date = dateString !== undefined && typeof dateString === 'string' ? moment(dateString, 'YYYY-MM-DD') : dateString;

    return date && {
        year: date.format('yyyy'),
        activeText: textType == 'day' ? date.format('D MMM') : textType == 'week' ? `${date.format('W')} Week` : `${date.format('MMMM')}`
    }
}

const DateLabel = (props: {
    value: string | moment.Moment | undefined;
    type: DateType;
    small?: boolean;
}) => {
    const obj = handleDateString(props.type, props.value);

    return(<div className={classNames({
        [styles.labelWrapper]: true,
        [styles.control]: props.small && props.small
    })}>
        <span className={styles.activeText}>{obj ? obj.activeText : '00'},</span>
        <span className={styles.labelYear}>{obj ? obj.year : 'MTH'}</span>
    </div>)
}

export default DateLabel