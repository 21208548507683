import {Box, Chip, styled} from '@mui/material';
import React from 'react';
import {AutoCompleteItemValue} from '../../../../shared/nextgen/selector/types';
import {FormattedMessage, useIntl} from 'react-intl';

import styles from './property-filter-popup.module.scss'
import {Lx} from '../../../../../i18n/consts';

interface Props {
    selectedEmployees: AutoCompleteItemValue[],
    selectedProjects: AutoCompleteItemValue[],
    selectedShiftTypes: AutoCompleteItemValue[],
    selectedAbsenceTypes: AutoCompleteItemValue[],
    selectedStatuses: AutoCompleteItemValue[],
}

const ChipWrapper = styled(Box)({
    display: 'flex',
    flexWrap: 'wrap',
    gap: '0.4rem'
})

export default function FilterListPreview(props: Props){
    const intl = useIntl();
    const { selectedEmployees, selectedProjects, selectedShiftTypes, selectedAbsenceTypes, selectedStatuses } = props;
    return(<Box display='flex' flexDirection='column' padding={'8px'} gap={'8px'} maxWidth={300} className={styles.filtersView}>
        {
            selectedEmployees.length > 0 && <>
                <FormattedMessage id={Lx.General.EMPLOYEES}/>
                <div className={styles.filtersSeparator}/>
                <ChipWrapper>
                    {selectedEmployees.map((e, key) => (
                        <Chip label={e.name} title={e.name} key={key} size='medium'/>))}
                </ChipWrapper>
            </>
        }
        {
            selectedProjects.length > 0 && <>
                <FormattedMessage id={Lx.General.PROJECTS} />
                <div className={styles.filtersSeparator}/>
                <ChipWrapper>
                    {selectedProjects.map((e, key) => (
                        <Chip label={e.name} title={e.name} key={key} size='medium'/>))}
                </ChipWrapper>
            </>
        }
        {
            (selectedShiftTypes.length > 0 || selectedAbsenceTypes.length > 0) && <>
                <FormattedMessage id={Lx.General.TYPES} />
                <div className={styles.filtersSeparator}/>
                <ChipWrapper>
                    {selectedShiftTypes.map((e, key) => (
                        <Chip label={e.name} title={e.name} key={key} size='medium'/>))}
                    {selectedAbsenceTypes.map((e, key) => (
                        <Chip label={e.name} title={e.name} key={key} size='medium'/>))}
                </ChipWrapper>
            </>
        }
        {
            selectedStatuses.length > 0 && <>
                <FormattedMessage id={Lx.General.STATUS} />
                <div className={styles.filtersSeparator}/>
                <ChipWrapper>
                    {selectedStatuses.map((e, key) => {
                        const name = intl.formatMessage({ id: e.name });
                        return (<Chip label={name} title={name} key={key} size='medium' />)
                    })}
                </ChipWrapper>
            </>
        }
    </Box>)
};