import React, {useEffect, useState} from 'react';
import TimiDataGrid from '../../../../components/shared/grid';
import {
    GridRef,
    GridColumnProps,
    GridGroupByProps
} from '../../../../components/shared/grid/types';
import {Lx} from  '../../../../i18n/consts';
import {IntlShape} from 'react-intl';
import GroupEmployeeRow from '../group-employee-row';
import styles from './time-report-grid.module.scss';
import { useTimeReportSearch } from '../../contexts/search';
import classNames from 'classnames';
import { 
    GetGroupedAbsenceHours, 
    GetGroupedApprovedHours, 
    GetGroupedBilledHours, 
    GetGroupedOvertimes,
    GetGroupedWorkHours, 
    TimeReportItem 
} from '../../../../features/time-report/types';
import _ from 'lodash';

interface TimeReportGridProps {
    data: TimeReportItem[];
    intl: IntlShape;
}

const getGroupBy = (): GridGroupByProps => {
    return {
        rowGroupingModel: ['employeeName'],
        groupByRenderer: (groupField, groupFieldValue, value) => {
            const items = value as TimeReportItem[];
            return(<GroupEmployeeRow 
                employeeName={groupFieldValue}
                workHours={GetGroupedWorkHours(items)}
                approvedHours={GetGroupedApprovedHours(items)}
                billedHours={GetGroupedBilledHours(items)}
                absenceHours={GetGroupedAbsenceHours(items)}
                overtimeHours={GetGroupedOvertimes(items)} />)
        }
    }
}

const widthHourColumn = 88;
const getColumns = (): GridColumnProps[] => {
    return [
        {
            field: 'client', 
            localizationKey: Lx.General.CLIENT, 
            editable: false,
            flex: { grow: 1 },
            value: (row) => (row as TimeReportItem).clientId,
            displayValue: (value, row) => (row as TimeReportItem).clientName,
            isHeaderCollapseButton: true,
            setBackground: true,
            alignment: 'center'
        },
        {
            field: 'project',
            localizationKey: Lx.General.PROJECT,
            editable: false,
            flex: { grow: 1 },
            value: (row) => (row as TimeReportItem).projectId,
            displayValue: (value, row) => (row as TimeReportItem).projectName,
            setBackground: true,
            alignment: 'center'
        },
        {   
            field: 'absenceHours', 
            localizationKey: Lx.Schedule.ABSENCE_HOURS_ABBREVIATION, 
            width: widthHourColumn, 
            type: 'number', 
            displayValue: (value, row) => {
                const item = row as TimeReportItem;
                return <div className={classNames({[styles.cell]:true})}>{item.absenceHours == 0 ? '' : item.absenceHours}</div>
            },
            setBackground: true,
            position: 'end',
            alignment: 'center'
        },
        { 
            field: 'overtime', 
            localizationKey: Lx.Schedule.OVERTIME_HOURS_ABBREVIATION, 
            type: 'number',
            width: widthHourColumn, 
            displayValue: (value, row) => {
                const item = row as TimeReportItem;
                return <div className={classNames({[styles.cell]:true})}>{item.overtimes == 0 ? '' : item.overtimes}</div>
            }, 
            setBackground: true
        },
        { 
            field: 'workedHours', 
            localizationKey: Lx.Schedule.WORKED_HOURS_ABBREVIATION, 
            type: 'number',
            width: widthHourColumn, 
            displayValue: (value, row) => {
                const item = row as TimeReportItem;
                return <div className={classNames({[styles.cell]:true})}>{item.workHours == 0 ? '' : item.workHours}</div>;
            }, 
            setBackground: true,
            position: 'end',
            alignment: 'center'
        },
        { 
            field: 'approved', 
            localizationKey: Lx.Schedule.APPROVED_HOURS, 
            type: 'number',
            width: widthHourColumn, 
            displayValue: (value, row) => {
                const item = row as TimeReportItem;
                return item.approvedHours > 0
                    ? <div className={classNames({[styles.cell]:true,[styles.approved]:true})}>{item.approvedHours}</div>
                    : <div className={classNames({[styles.cell]:true,[styles.approved]:true,[styles.isZero]:true})}>{'0'}</div>
                    
            }, 
            setBackground: true,
            position: 'end',
            alignment: 'center'
        },
        { 
            field: 'billable', 
            localizationKey: Lx.Schedule.BILLABLE_HOURS, 
            type: 'number',
            width: widthHourColumn, 
            displayValue: (value, row) => {        
                const item = row as TimeReportItem;    
                return item.billedHours > 0 
                    ? <div className={classNames({[styles.cell]:true,[styles.billed]:true})}>{item.billedHours}</div>
                    : <div className={classNames({[styles.cell]:true,[styles.billed]:true,[styles.isZero]:true})}>{'0'}</div>
            }, 
            setBackground: true,
            position: 'end',
            alignment: 'center'
        }
    ]
}

const TimeReportGrid: React.FC<TimeReportGridProps> = (props) => {
    const { data } = props;
    const { value: search } = useTimeReportSearch();
    const [columns, setColumns] = useState<Array<GridColumnProps>>(getColumns());
    const [groupBy, setGroupBy] = useState<GridGroupByProps>(getGroupBy());
    const gridRef = React.useRef<GridRef>(null);

    const rows = React.useMemo(() => {
        const trimSeaech = search.trim();
        if (trimSeaech === ''){
            return data;
        }

        const toSearch = trimSeaech.toLowerCase();
        const filteredData = data.filter((s) => {
            return s.employeeName.toLowerCase().includes(toSearch)
                || s.projectName.toLowerCase().includes(toSearch)
                || s.clientName.toLowerCase().includes(toSearch);
        });
        
        return _.orderBy(filteredData, x => x.employeeName);
    }, [data, search]);

    useEffect(() => {
        setGroupBy(getGroupBy());
        setColumns(getColumns());
    }, [data]);

    return (<TimiDataGrid
        hideHeader={false} 
        columns={columns} 
        rows={rows} 
        groupBy={groupBy} 
        intl={props.intl} 
        ref={gridRef} />)
}

export default TimeReportGrid;