import React, {useEffect, useState} from 'react';
import styles from './selector.module.scss'
import CheckIcon from '@mui/icons-material/Check';
import {AutoCompleteItemValue} from './types';
import useAutocomplete from '@mui/material/useAutocomplete';
import {SVGS} from '../../../../assets/images';
import {Chip} from '@mui/material';
import classNames from 'classnames';
import {IntlShape} from 'react-intl';
import {Lx} from '../../../../i18n/consts';
import Listbox from '../../listbox'
import Popper from '@mui/material/Popper';

interface Props {
    name: string;
    label: string;
    items: AutoCompleteItemValue[];
    setSelectedValues: (values: AutoCompleteItemValue[]) => void;
    selected?: AutoCompleteItemValue[];
    id?: string;
    intl?: IntlShape;
    maxWidth?: number;
    groups?: boolean;
}

const Selector: React.FC<Props> = (props) => {
    const {name, label, id, items, setSelectedValues, selected, groups, intl} = props;
    const [placeholder, setPlaceholder] = useState(intl ? intl.formatMessage({id: label}) : label);
    const defaultValues: AutoCompleteItemValue[] = (selected ?? []).map(s => {
        return items.find((i) => i.id === s.id) as AutoCompleteItemValue;
    });
    const {
        getRootProps,
        getInputProps,
        getListboxProps,
        getOptionProps,
        groupedOptions,
        setAnchorEl,
        anchorEl,
        focused,
        getClearProps,
        popupOpen,
        value,
        getTagProps,
    } = useAutocomplete({
        id: id ? id : 'autocomplete',
        value: defaultValues,
        multiple: true,
        options: items,
        getOptionLabel: (option) => (option as AutoCompleteItemValue).name,
        getOptionKey: (option) => (option as AutoCompleteItemValue).id,
        filterOptions: (options, state) => {
            const filter = options.
                filter((x) => x.name.toLowerCase().includes(state.inputValue.toLowerCase()));
            return filter;
        },
        onChange: (e, value) => {
            setSelectedValues(value as AutoCompleteItemValue[])
        },
        isOptionEqualToValue: (option, value) => option.id === value.id,
        openOnFocus: true,
        freeSolo: true,
        disableCloseOnSelect: true
    });

    const valueToRender = (value as AutoCompleteItemValue[]).filter((x) => x !== undefined).slice(0, Math.floor((anchorEl?.clientWidth ?? 266) / 90));
    const leftValues = (value as AutoCompleteItemValue[]).length - valueToRender.length;

    useEffect(() => {
        if (name) {
            if (selected && selected.length > 0) {
                setPlaceholder(intl ? `${intl.formatMessage({id: Lx.General.ITEM_OF_ITEM_FORMAT}, {
                    f: selected.length,
                    s: items.length
                })} ${intl.formatMessage({id: name})}` : `${selected.length} of ${items.length} ${name}`);
            } else {
                setPlaceholder(intl ? intl.formatMessage({id: label}) : label);
            }
        }
    }, [selected?.length]);

    return (<div className={classNames({
        [styles.selector]: true,
        [styles.active]: selected && selected.length > 0
    })}>
        {label !== '' && <div className={styles.selectorHeader}><label>{intl?.formatMessage({id: label})}</label><button {...getClearProps()}>Clear</button></div>}
        <div {...getRootProps()}>
            <div ref={setAnchorEl}
                className={classNames({
                    [styles.inputWrapper]: true,
                    [styles.wrapperFocus]: focused,
                    [styles.wrapperActive]: selected && selected.length > 0,
                    'max-width': props.maxWidth ?? 'auto'
                })}>
                <div className={styles.contentWrapper}>
                    <div className={classNames({
                        [styles.tagsWrapper]: true,
                        [styles.active]: value.length > 0,
                    })}>
                        <>
                            {valueToRender.map((option: AutoCompleteItemValue, index: number) => {
                                const {key, ...tagProps} = getTagProps({index});
                                return <Chip label={option.name} title={option.name} key={key} {...tagProps} size={'small'}
                                    sx={{maxWidth: 90}}/>;
                            })}
                            {
                                value.length > valueToRender.length && <>+{leftValues}</>
                            }
                        </>
                    </div>
                    <input {...getInputProps()} placeholder={focused || value.length > 0 ? '' : placeholder}/>
                </div>
                <div className={styles.right}>
                    <span className={styles.popperIcon}><SVGS.DropdownIcon id={'dropdown-icon'}/></span>
                </div>
            </div>
        </div>
        {
            anchorEl && (<Popper open={popupOpen} anchorEl={anchorEl} sx={{zIndex: 9999}} placement={'bottom-start'}>
                {groupedOptions.length > 0 ? (
                    <Listbox className={styles.listBox} {...getListboxProps()} sx={{minWidth: anchorEl?.clientWidth ?? 0}}>
                        {
                            (groupedOptions as AutoCompleteItemValue[]).map((option, index) => {
                                const { key, ...optionProps } = getOptionProps({option, index});
                                return (
                                    <li {...optionProps} key={key}>
                                        <span>{option.name}</span>
                                        <CheckIcon fontSize="small"/>
                                    </li>);
                            })
                        }
                    </Listbox>
                ) : null}
            </Popper>)
        }
    </div>);
}

export default React.memo(Selector);