import React, {useEffect, useState} from 'react';
import TimiDataGrid from '../../../../components/shared/grid';
import {
    GridRef,
    GridRowModel
} from '../../../../components/shared/grid/types';
import {
    TimeSheetGroupType,
    TimeSheetItem,
    TimeSheetItemStatus,
    TimeSheetItemType,
    TimeSheetItemUpdateState
} from '../../../../features/schedule/types';
import {IntlShape} from 'react-intl';
import {TiminatorAbsenceTypes, TiminatorTypes} from '../../../../features/types/types';
import {Employee} from '../../../../features/employees/types';
import {PIdNameModel} from '../../../../features/projects/types';
import { useTimeSheetGroupBy } from '../../contexts/group-by';
import useGridColumns from './hooks/columns';
import useGridGroupBy from './hooks/group-by';
import {useTimeSheetSearch} from '../../contexts/search';
import _ from 'lodash';

interface TimeSheetGridProps {
    data: TimeSheetItem[];
    intl: IntlShape;
    shiftTypes: TiminatorTypes;
    absenceTypes: TiminatorAbsenceTypes;
    projects: PIdNameModel[],
    forceSwitch: boolean;
    notifySwitch: (isAny: boolean) => void;
    onStateChange: (state: Array<TimeSheetItemUpdateState>) => Promise<void>;
    onTimeshiftUpdate: (timeshift: TimeSheetItem) => void;
    onAddNewShiftClick: (targetTimeshift: TimeSheetItem) => Promise<void>;
    onEditItemClick: (timeshift: TimeSheetItem) => void;
    getShiftEmployee: (timeshift: TimeSheetItem) => Promise<Employee>;
    onDeleteItemClick: (timeshift: TimeSheetItem) => void;
}

const toTimeSheetItemUpdateState = (timesheet: TimeSheetItem, newStatus: TimeSheetItemStatus): TimeSheetItemUpdateState => ({
    timesheetId: timesheet.itemId,
    timesheetItemType: timesheet.timeshiftType,
    newStatus,
    previousStatus: timesheet.status
})

const TimeSheetGrid: React.FC<TimeSheetGridProps> = (props) => {
    const { data, forceSwitch} = props;
    const { value: search } = useTimeSheetSearch();
    const { groupBy } = useTimeSheetGroupBy();
    const ordered = React.useMemo(() => {

        if (groupBy === TimeSheetGroupType.PROJECTVIEW) {
            return _.chain(data)
                .orderBy((x) => x.employeeName)
                .orderBy((x) => x.date)
                .orderBy((x) => x.targetName)
                .orderBy((x) => x.timeshiftType)
                .orderBy((x) => x.hours === 0)                
                .value();
        }

        return _.chain(data)
            .orderBy((x) => x.date)
            .orderBy((x) => x.employeeName)
            .orderBy((x) => x.hours === 0)
            .value();
    }, [data, groupBy]);

    const rows = React.useMemo(() => {
        const trimSearch = search.trim();
        if (trimSearch === ''){
            return ordered;
        }

        const toSearch = trimSearch.toLowerCase();
        return ordered.filter((s) => {
            return s.employeeName.toLowerCase().includes(toSearch)
                || s.targetName.toLowerCase().includes(toSearch)
                || (s.note !== undefined && s.note !== null && s.note.toLowerCase().includes(toSearch))
                || (s.internalNote !== undefined && s.internalNote !== null && s.internalNote.toLowerCase().includes(toSearch));
        })
    }, [ordered, search])
    const rowsMap = React.useMemo(() => _.keyBy(rows, 'id'), [rows]);
    const gridRef = React.useRef<GridRef>(null);

    const handleApproveAllClick = async (items: Array<TimeSheetItem>) => {
        await props.onStateChange(items
            .filter((x) => x.status === TimeSheetItemStatus.NORMAL)
            .map((ts) =>
                toTimeSheetItemUpdateState(ts, TimeSheetItemStatus.APPROVED)));
    }

    const handleBillAllClick = async (items: Array<TimeSheetItem>) => {
        await props.onStateChange(items
            .filter((x) => x.timeshiftType === TimeSheetItemType.SHIFT && x.status !== TimeSheetItemStatus.BILLABLE)
            .map((ts) =>
                toTimeSheetItemUpdateState(ts, TimeSheetItemStatus.BILLABLE)));
    }

    const handleDisapproveAllClick = async (items: Array<TimeSheetItem>) => {
        await props.onStateChange(items
            .filter((x) => x.status !== TimeSheetItemStatus.NORMAL)
            .map((ts) =>
                toTimeSheetItemUpdateState(ts, TimeSheetItemStatus.NORMAL)))
    }

    const handleCancelAllBillingClick = async (items: Array<TimeSheetItem>) => {
        await props.onStateChange(items
            .filter((x) => x.timeshiftType === TimeSheetItemType.SHIFT && x.status === TimeSheetItemStatus.BILLABLE)
            .map((ts) => 
                toTimeSheetItemUpdateState(ts, TimeSheetItemStatus.APPROVED)))
    }

    const handleAnyRowExpand = (isAnyRowExpand: boolean) => {
        props.notifySwitch(isAnyRowExpand);
    }

    const columns = useGridColumns(
        groupBy,
        props.shiftTypes,
        props.absenceTypes,
        props.projects,
        async (state) => await (props.onStateChange([state])),
        props.onAddNewShiftClick,
        props.onEditItemClick,
        props.getShiftEmployee,
        props.onDeleteItemClick
    );

    const gridGroupBy = useGridGroupBy(
        groupBy,
        handleApproveAllClick,
        handleBillAllClick,
        handleDisapproveAllClick,
        handleCancelAllBillingClick,
        props.onAddNewShiftClick
    )

    useEffect(() => {
        gridRef.current?.collapseRows();
    }, [forceSwitch])

    const handleRowEditEnd = (row: GridRowModel, state: GridRowModel) => {
        const updateItem = { ...rowsMap[row.id], ...state };
        props.onTimeshiftUpdate(updateItem);
    };

    return (<TimiDataGrid hideHeader columns={columns} rows={rows} groupBy={gridGroupBy} intl={props.intl} onRowEditEnd={handleRowEditEnd} editDataLoadingMode={'server'} ref={gridRef} onAnyRowExpand={handleAnyRowExpand} />)
}

export default TimeSheetGrid;