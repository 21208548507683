import React from 'react';
import { SVGS } from '../assets/images'
import {IAppRouter} from './interfaces/IAppRouter'
import SchedulePage from '../pages/Schedule';
import TimeReportPage from '../pages/TimeReport';

const ROUTES: IAppRouter = {
    main: '/',
    login: '/auth',
    linkProceed: '/user/email-magic-link',
    logout: {
        name: 'Logout',
        url: '/logout',
    },
    types: {
        name: 'types',
        url: '/types',
        // eslint-disable-next-line react/react-in-jsx-scope
        svg: <SVGS.TypesIcon />,
    },
    roles: {
        name: 'roles',
        url: '/roles',
        // eslint-disable-next-line react/react-in-jsx-scope
        svg: <SVGS.RolesIcon />,
    },
    employees: {
        name: 'employees',
        url: '/employees',
        // eslint-disable-next-line react/react-in-jsx-scope
        svg: <SVGS.EmployeesIcon />,
    },
    clients: {
        name: 'clients',
        url: '/clients',
        // eslint-disable-next-line react/react-in-jsx-scope
        svg: <SVGS.ClientsIcon />,
    },
    projects: {
        name: 'projects',
        url: '/projects',
        // eslint-disable-next-line react/react-in-jsx-scope
        svg: <SVGS.ProjectsIcon />,
    },
    settings: {
        name: 'settings',
        url: '/settings',
        // eslint-disable-next-line react/react-in-jsx-scope
        svg: <SVGS.SettingsIcon />,
    },
    manage: {
        name: 'manage',
        url: '/',
        // eslint-disable-next-line react/react-in-jsx-scope
        svg: <SVGS.ProfileIcon />,
    },
    schedule: {
        name: 'schedule',
        url: '/timesheet',
        element: <SchedulePage />,
        // eslint-disable-next-line react/react-in-jsx-scope
        svg: <SVGS.AddChartIcon />,
        children: [
            {
                name: 'timesheet',
                url: '/timesheet',
                element: <SchedulePage />,
            },
            {
                name: 'timereport',
                url: '/timereport',
                element: <TimeReportPage />,
            }
        ]
    },
    zoho: '/zoho',
}

export default ROUTES
