import React from 'react';
import {TimeSheetGroupType} from '../../../../features/schedule/types';

const notImplemented = () => {
    throw new Error('method not implemented');
}

export interface TimeSheetGroupByContext {
    groupBy: TimeSheetGroupType;
    change: (value: TimeSheetGroupType) => void;
}

export const initialState: TimeSheetGroupByContext = {
    groupBy: TimeSheetGroupType.EMPLOYEEVIEW,
    change: notImplemented
}

export const TimeSheetGroupByContext = React.createContext(initialState);