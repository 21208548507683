import React from 'react';
import { TimeSheetItemStatus } from '../../../features/schedule/types';
import { SVGS } from '../../../assets/images';
import classNames from 'classnames';

import styles from './state-icon.module.scss';

const StateIcon = (props: { status: TimeSheetItemStatus, active: boolean }) => {
    switch (props.status) {
        case TimeSheetItemStatus.NORMAL:
            return (<SVGS.CheckIcon className={classNames({
                [styles.icon]: true,
                [styles.normal]: true
            })} />)
        case TimeSheetItemStatus.APPROVED:
            return (<SVGS.ApproveIcon className={classNames({
                [styles.icon]: true,
                [styles.normal]: !props.active
            })} />)
        default:
            return (<SVGS.MoneyIcon className={classNames({
                [styles.icon]: true,
                [styles.normal]: !props.active
            })}/>)
    }
}

export default StateIcon;