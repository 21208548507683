import {DateRangeFilter, PropertyFilter, TimeSheetItem} from './types';
import {store} from '../../store/store';
import timeSheetReducer, {clearData, setData, updateDateRangeFilter, updatePropertyFilter} from './slice';

export const updateTimesheetPropertyFilter = (filter: PropertyFilter) => {
    store.dispatch(updatePropertyFilter(filter));
}

export const updateTimesheetDateRangeFilter = (filter: DateRangeFilter) => {
    store.dispatch(updateDateRangeFilter(filter));
}

export const setTimesheetData = (data: Array<TimeSheetItem>) => {
    store.dispatch(setData(data))
}

export const clearTimesheetData = () => {
    store.dispatch(clearData())
}

export { timeSheetReducer };