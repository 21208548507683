import React, {useMemo} from 'react';
import {GridColumnProps, GridIdNameModel} from '../../../../../../components/shared/grid/types';
import {Lx} from '../../../../../../i18n/consts';
import {
    TimeSheetGroupType,
    TimeSheetItem,
    TimeSheetItemType, TimeSheetItemUpdateState
} from '../../../../../../features/schedule/types';
import _ from 'lodash';
import styles from '../../grid.module.scss';
import {TimeSheetItemView} from '../../../../../../components/Schedule/time-shift';
import {TiminatorAbsenceTypes, TiminatorTypes} from '../../../../../../features/types/types';
import {PIdNameModel} from '../../../../../../features/projects/types';
import {Employee} from '../../../../../../features/employees/types';


const useGridColumns = (
    groupType: TimeSheetGroupType,
    shiftTypes: TiminatorTypes,
    absenceTypes: TiminatorAbsenceTypes,
    projects: PIdNameModel[],
    onTimeshiftStateChange: (state: TimeSheetItemUpdateState) => void,
    onAddShiftClick: (timeshift: TimeSheetItem) => void,
    onEditItemClick: (timeshift: TimeSheetItem) => void,
    getShiftEmployee: (timeshift: TimeSheetItem) => Promise<Employee>,
    onDeleteItemClick: (timeshift: TimeSheetItem) => void,
): GridColumnProps[] => useMemo(() => [
    {
        field: 'date', localizationKey: Lx.Schedule.TIMESHIFT_DATE, noHeader: true, type: 'date', editable: true
    },
    {
        field: 'target',
        localizationKey: Lx.Schedule.TIMESHIFT_TARGET,
        width: 160,
        editable: true,
        type: 'singleSelect',
        selectableOptions: async (row): Promise<GridIdNameModel[]> => {
            const timeshift = row as TimeSheetItem;
            if (timeshift.timeshiftType !== TimeSheetItemType.SHIFT)
                return _.chain(absenceTypes.items).orderBy((x) => x.default ? 0 : x.id).map((x) => ({
                    id: x.id,
                    name: x.name
                })).value();

            return _.chain((await getShiftEmployee(timeshift)).projects).orderBy((p) => p.id).map((x) => ({
                id: x.id,
                name: x.name
            })).value()
        },
        value: (row) => (row as TimeSheetItem).targetId,
        displayValue: (value, row, _intl) => {
            const timeshift = row as TimeSheetItem
            const id = value as number
            if (timeshift.timeshiftType === TimeSheetItemType.SHIFT) {
                return projects.find((x) => x.id === id)?.name ?? `Project [${id}]`
            }
            return absenceTypes.items.find((x) => x.id === id)?.name ?? `Absence [${id}]`;
        },
        visible: groupType === TimeSheetGroupType.EMPLOYEEVIEW,
        colSpan: (row) => (row as TimeSheetItem).timeshiftType === TimeSheetItemType.ABSENCE ? 2 : null
    },
    { field: 'employeeName', localizationKey: Lx.General.EMPLOYEE, width: 160, type: 'string', visible: groupType === TimeSheetGroupType.PROJECTVIEW },
    {
        field: 'type', localizationKey: Lx.Schedule.TIMESHIFT_TYPE, width: 160,
        value: (row) => ((row as TimeSheetItem).timeshiftTypeId),
        displayValue: (value, row, _intl) => {
            const timeshift = row as TimeSheetItem
            if (timeshift.timeshiftType === TimeSheetItemType.SHIFT) {
                const id = value as number
                return shiftTypes.items.find((x) => x.id === id)?.name ?? `ShiftType [${id}]`
            }
            return absenceTypes.items.find((x) => x.id === timeshift.timeshiftTypeId)?.name ?? 'AbsenceType'
        },
        editable: true,
        type: 'singleSelect',
        selectableOptions: _.orderBy(shiftTypes.items, (x) => x.default ? 0 : x.id).map((x) => ({ id: x.id, name: x.name }))
    },
    {field: 'note', localizationKey: Lx.Schedule.TIMESHIFT_NOTE, flex: { grow: 2 }, muted: true, editable: true, type: 'text'},
    {field: 'internalNote', localizationKey: Lx.Schedule.TIMESHIFT_INTERNAL_NOTE, flex: true, muted: true, editable: true, type: 'text'},
    {
        field: 'hours', localizationKey: Lx.Schedule.TIMESHIFT_HOURS, cellRender: (field, row, intl) => {
            const timeSheetItem = row as TimeSheetItem;
            return (<div className={styles.timeShiftCell}>
                <TimeSheetItemView hours={timeSheetItem.hours}
                    status={timeSheetItem.status}
                    intl={intl} note={timeSheetItem.note}
                    type={{ id: timeSheetItem.timeshiftTypeId, timeSheetType: timeSheetItem.timeshiftType }}
                    size={'small'}
                    updateState={(status, previous) =>
                        onTimeshiftStateChange({ timesheetId: timeSheetItem.itemId, timesheetItemType: timeSheetItem.timeshiftType, newStatus: status, previousStatus: previous })}
                    onAddNewShiftClick={() => onAddShiftClick(timeSheetItem)}
                    onEditItemClick={() => onEditItemClick(timeSheetItem)}
                    onDeleteItemClick={() => onDeleteItemClick(timeSheetItem)}/>
            </div>)
        }
    }
], [groupType, shiftTypes, absenceTypes, projects, onTimeshiftStateChange, onAddShiftClick, onEditItemClick, getShiftEmployee, onDeleteItemClick])

export default useGridColumns;