import {CalendarDate, CustomRendererProps, SingleSelectionProps, MonthDayCell, RangeSelectionProps, Year} from './types';
import moment from 'moment/moment';
import {ReactNode} from 'react';
import month from '../../../pages/Settings/components/work-calendar/components/month';

export const isRange = (obj: any): obj is RangeSelectionProps => {
    return obj != null && 'range' in obj;
}

export const isDefault = (obj: any): obj is SingleSelectionProps => {
    return obj != null && 'value' in obj;
}

export const isHasRenderer = (obj: any): obj is CustomRendererProps => {
    return obj != null && 'renderer' in obj;
}

export const formatDateRange = (first: CalendarDate, second: CalendarDate): Array<CalendarDate> => {
    if (second.year < first.year) {
        return [second, first];
    }
    if (second.month < first.month) {
        return [second, first];
    }
    if ((first.month > second.month && first.day <= second.day) || (first.month == second.month && first.day > second.day)) {
        return [second, first];
    }

    return [first, second];
};

export const getMonth = (move?: number): { year: number, month: number, start: number, end: number } => {
    let today = moment();
    if (move) {
        today = today.add(move, 'month')
    }

    const start = today.startOf('month');
    const end = today.clone().endOf('month');

    return {
        year: parseInt(start.format('YYYY')),
        month: parseInt(start.format('MM')),
        start: parseInt(start.format('DD')),
        end: parseInt(end.format('DD'))
    }
};

export const getWeek = (move?: number): { year: number, start: { month: number, day: number }, end: { month: number, day: number } } => {
    let today = moment();
    if (move) {
        today = today.add(move, 'week');
    }

    const start = today.startOf('week');
    const end = today.clone().endOf('week');

    return {
        year: parseInt(start.format('YYYY')),
        start: {
            day: parseInt(start.format('DD')),
            month: parseInt(start.format('MM'))
        },
        end: {
            day: parseInt(end.format('DD')),
            month: parseInt(end.format('MM'))
        }
    }
};

export const isMonthDayCellArray = (objs: MonthDayCell[] | ReactNode[]): objs is MonthDayCell[] => {
    return (<MonthDayCell[]>objs).some((x) => x.day !== undefined);
}

export const generateCurrentYear = (): Year => {
    const year = moment().year();

    return {
        id: year,
        months: Array.from({ length: 12 },(_, i) => i + 1).map((n, i) => {
            const days = moment(`${year}-${n}`, 'YYYY-MM').daysInMonth();
            return {
                id: n,
                days: Array.from({ length: days }, (_, ix) => ix + 1).map((nx, ix) => ({ id: nx, type: 'default' }))
            }
        }),
    }
}