import React from 'react';
import {initialState, TimeReportSearchContext} from './context';

const TimeReportSearchProvider = (props: { children: React.ReactNode }) => {
    const [state, setState] = React.useState(initialState);

    const config: TimeReportSearchContext = {
        ...initialState,
        ...state,
        change: (value) => setState((v) => ({ ...v, value })),
    }

    return <TimeReportSearchContext.Provider value={config}>
        {props.children}
    </TimeReportSearchContext.Provider>
}

export const useTimeReportSearch = (): TimeReportSearchContext => React.useContext(TimeReportSearchContext);

export default TimeReportSearchProvider;