import {AxiosInstance, AxiosResponse} from 'axios';
import {AbsenceType} from './types';

export interface IAbsencesApi {
    getMany<T>(dateFrom: string, dateTo: string, employees: number[], types: number[]): Promise<AxiosResponse<T>>;
    get<T>(id: number): Promise<AxiosResponse<T>>;
    update<T>(id: number, body: T): Promise<AxiosResponse<T>>;
    updateType<T>(id: number, absenceType: AbsenceType): Promise<AxiosResponse<T>>;
    create<T>(body: T): Promise<AxiosResponse<T>>;
    delete(id: number): Promise<AxiosResponse>;
}

export default class AbsencesApi implements IAbsencesApi {
    private readonly _axiosInstance;
    private readonly _controller: string = 'absences';
    private readonly _headers = { headers: { 'Content-Type': 'application/json' } };

    constructor(axiosInstance: AxiosInstance) {
        this._axiosInstance = axiosInstance;
    }

    async getMany<T>(dateFrom: string, dateTo: string, employees: number[], types: number[]): Promise<AxiosResponse<T>> {
        const employeesSearch = employees.map((x) => `&employees=${x}`).join('');
        const typesSearch = types.map((x) => `&absenceTypes=${x}`).join('');
        return await this._axiosInstance.get<T>(`${this._controller}?from=${dateFrom}&to=${dateTo}${employeesSearch}${typesSearch}`);
    }
    async get<T>(id: number): Promise<AxiosResponse<T>> {
        return await this._axiosInstance.get<T>(`${this._controller}/${id}`);
    }
    async update<T>(id: number, body: T): Promise<AxiosResponse<T>> {
        return await this._axiosInstance.put(`${this._controller}/${id}`, JSON.stringify(body), this._headers);
    }
    async updateType<T>(id: number, absenceType: AbsenceType): Promise<AxiosResponse<T>> {
        return await this._axiosInstance.put(`${this._controller}/${id}`, JSON.stringify(absenceType), this._headers);
    }
    async create<T>(body: T): Promise<AxiosResponse<T>> {
        return await this._axiosInstance.post(`${this._controller}`, JSON.stringify(body), this._headers);
    }
    async delete(id: number): Promise<AxiosResponse> {
        return await this._axiosInstance.delete(`${this._controller}/${id}`);
    }
}